card-container {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  flex: 1;
  height: 179px;
  margin-right: 20px;
  color: #224373;
  h6 {
    color: #224373;
  }
}
.textLayer {
  span {
    color: transparent !important;
  }
}
.ReactModalPortal {
  &::-webkit-scrollbar {
    width: 10px !important;
  }
}
.ReactModal__Overlay--after-open {
  z-index: 999 !important;
}
.date__casenotes__left {
  position: relative;
  left: 27px;
}
.internal__notes__body {
  padding: 10px 20px;
}
.casenotes__img__modal {
  float: right;
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
  transform: scale(1.2);
  cursor: pointer;
  z-index: 998;
}
.add__last__section {
  border: none;
  background-color: #1ca8ff;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  margin-left: 10px;
}
.html__title__wrapper {
  width: 100%;
  height: 50px;
  background-color: #1ca8ff;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  svg {
    color: #ffffff;
    right: 10px;
    position: relative;
  }
}
.internal__text__ellipse {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px 20px;
  text-overflow: ellipsis;
  cursor: pointer;
}
.html__title {
  position: relative;
  padding: 20px;
  color: #ffffff;
  font-weight: 700;
  h4,
  h6 {
    color: #ffffff;
  }
}

.Example__container__document {
  margin: 1em 0;
  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .react-pdf__Page {
    max-width: calc(100% - 2em);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
    canvas {
      max-width: 100%;
      height: auto !important;
    }
  }
  .react-pdf__message {
    padding: 20px;
    color: #224373;
  }
}
.shared__upload__title {
  height: 35px;
  padding: 10px 15px;
  color: #224373 !important;
  position: sticky;
  top: 0;

  z-index: 4;
  background: #ffffff;

  h1 {
    color: #224373 !important;
  }
  button {
    border: none;
    background-color: #224373;
    padding: 10px 15px;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
  }
}

.shared__upload__item {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 2px 10px;
  color: #224373;
  overflow-y: auto;
  z-index: 3;
  img,
  svg {
    cursor: pointer;
    transform: scale(0.9);
  }
}
.view__link {
  color: #1ca8ff !important;
  font-size: 0.8em;
  position: relative;
  top: -15px;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px !important;
}
.prescription__save {
  padding: 2rem;
  margin-bottom: 0;
  position: relative;
  float: right;
}
.wait-timer {
  width: 12em;
  position: relative;
  left: 110px;
}
.chat-con {
  left: -9px;
  position: relative;
}
.orange-text {
  color: #ef9b55 !important;
  font-weight: bold;
}
.consultation-container {
  margin-left: 10px;
  padding: 0 10px;
}
.reason-box {
  width: 335px;
}
.follow-ups-container {
  margin-bottom: 50px;
  hr {
    margin: 4px 0 !important;
  }
}
.display-flx {
  display: flex;
  align-items: center;

  margin-bottom: -12px;
}
.display-new {
  display: flex;
  align-items: center;
}
.mrt-10 {
  margin-top: 10px;
}
.consultation-patient {
  margin-left: 20px;
}
.btns {
  width: 220px;
  height: 44px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
}
.reschedule {
  background: #224373;
  color: #fff;
}
.saf__button {
  position: relative;
  right: 30px;
}
.started-button {
  color: #40cdb4;
  font-weight: bold;
  font-family: 'Courier' !important;
  font-size: 1.9rem;
  position: relative;
  top: -10px;
  height: 26px;
}
.date-ip {
  height: 55px !important;
  width: 100%;
  > div.react-datepicker-wrapper {
    height: 55px !important;
  }
  > div {
    > div.react-datepicker__input-container {
      .date-ip {
        > div {
          > div.react-datepicker__input-container {
            input {
              height: 55px !important;
            }
          }
        }
      }
    }
  }
}
.cancel-consultation {
  color: #ec7474;
  font-weight: bold;
  width: 170px;
  margin-top: 10px;
  cursor: pointer !important;
  margin-left: 10px;
  text-decoration: underline;
  position: relative;
  left: 110px !important;
}
.reason-container {
  max-width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.reason-container-schedule {
  max-width: 250px;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.mrt--20 {
  top: -20px;
  position: relative;
}
.mrt--010 {
  top: -10px;
  position: relative;
}
.mrt--40 {
  position: relative;
  top: -40px;
}
.mrb--40 {
  margin-bottom: -30px !important;
  position: relative;
}
.mrt--40 {
  top: -40px;
}
.blue {
  color: #fff;
}
.confirm {
  color: #fff;
  background-color: #40cdb4;

  position: relative;
  margin-left: 0px;
}
.btns-new {
  left: -100px;
  position: relative;
}
.mrt--10 {
  top: -7px !important;
  position: relative;
  left: -25px;
}
.display-fx {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.row-direction {
  width: auto;
}
.row-direction-new {
  width: 130px;
  max-width: 130px;
}
.top-s-20 {
  margin-top: 20px;
  position: relative;
}

.elipse-txt {
  white-space: nowrap;

  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  pointer-events: none;
  cursor: pointer;
}
.elipse-txt:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  z-index: 1;
  pointer-events: initial;
}
.elipse:hover:after {
  cursor: pointer;
}
.ptb-btns {
  margin: 60px auto !important;

  flex-direction: row-reverse;
}
.tooltip {
  width: auto;
  visibility: hidden;
  background-color: #fff;
  padding: 20px;
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.5s ease;
  border-radius: 5px;
}
.elipse-txt:hover + .tooltip {
  visibility: visible;
  transition: opacity 0.5s ease;
  opacity: 1;
  border-radius: 5px;
}

.consultation-avatar {
  width: 100px;
  height: auto;
  border-radius: 50%;
  margin-top: 10px;
}
.healthcare-card {
  background-color: #ffffff;

  overflow-y: auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.consultation-history {
  width: 640px;
  height: 215px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow-y: auto;
}
.hr-class {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
.btns-right {
  float: right;
  display: flex;

  position: relative;
}
.row-big {
  width: 200px !important;
  min-width: 190px !important;
  position: relative;
}
.textarea-font {
  font-size: 18px !important;
}
.btns-rights {
  position: relative;
  left: -85px;
}
.top-10 {
  top: 10px;
  position: relative;
}
.table-sms td {
  border: none !important;
}
.body-bgv3 {
  border: none !important;
  color: #224373 !important;
  font-size: 16px;
}
.uploaded-documents {
  width: 660px;
  height: 215px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
}
.details-headers {
  color: #224373;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}
.details-info {
  color: #224373;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-top: -10px;
  margin-left: 15px;
}
.healthacard-heading {
  font-size: 22px !important;
}
.reassigned-heading {
  padding: 20px 0 10px 0;
  font-size: 22px !important;
}

.provider-doc-heading {
  margin-bottom: 20px;
  margin-left: 10px;
}
.medication-info {
  color: #224373;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  text-decoration: underline;
}
.case-history-info {
  font-size: 13px;
  line-height: 20px;
  color: #224373;
}
.pdf-icon {
  cursor: pointer;
  margin-top: -15px;
  width: 4%;
}
.case-history-date {
  font-size: 16px;
  line-height: 20px;
  color: #224373;
}
.inperson__clininc__name {
  position: relative;
  margin-left: 80px;
  font-size: 0.85em;
  p {
    width: 100%;
  }
  svg {
    font-size: 2em;
  }
}
.cancelled__inperson {
  margin-left: 20px;
}
.confirm__inperson {
  margin-left: -100px !important;
}
.medication-date {
  color: #224373;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  width: 33%;
}
.mrl-20 {
  margin-left: 12px;
  top: 10px;
  position: relative;
}
.mrl-10 {
  top: 10px;
  position: relative;
}
.badge-waiting {
  line-height: 20px;
  color: #ef9b55 !important;
  font-family: 'Courier' !important;
  font-size: 1.9rem;
}
.details-content {
  color: #224373;
}
.lite-blue {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px !important;
  width: 323px !important;
  height: 44px !important;
  font-size: 16px;
}
.big-wd {
  width: 250px !important;
}
.big-wds {
  width: 250px !important;
  margin-left: 80px !important;
}
.mrb {
  margin-bottom: 30px;
}
.left-mv {
  white-space: nowrap;
  top: -6px !important;
  position: relative;
}
.upload-buttons {
  background: #416bfb !important;
  border-radius: 5px !important;
  position: relative;
}
.fa-icons {
  i {
    font-size: 18px !important;
  }
}
.fa-icons-trash {
  i {
    font-size: 22px !important;
  }
}
.table-responsives {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 2px;
  border-radius: 25px 25px 0 0 !important;
  padding: 0 10px;
}
.mrf-15 {
  margin-left: 15px;
}
.flx-right {
  display: flex;
  float: right !important;
}
.reject-button {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #ec7474;
  cursor: pointer;
  margin-top: 10px;
}
.history-items {
  display: flex;
  flex-direction: column;
}
.btns-left {
  margin-left: 22px;
  position: absolute;
  top: -22px;
  left: -40px;
}
.join-btn-fx {
  display: flex;
  top: 3px;
  position: relative;
  left: 110px !important;
}
.li-blue {
  background-color: #1ca8ff;
  color: #ffffff;
  left: -5px;
  position: relative;
}
.left-join {
  left: 0px;
  position: relative;
}
.modal-content {
  width: 850px;
  height: 600px;
  border: 2px solid #66c0ff;
  margin: 0 auto;
  overflow-y: auto;
}
.modal-body {
  max-height: auto !important;
  min-height: auto !important;
  overflow-y: auto;
  span,
  label {
    font-size: 15px !important;
    color: #224373 !important;
    font-weight: bold !important;
  }

  small {
    color: #9aaac1;
  }
}
.consultation-heading {
  font-size: 18px !important;
  color: #224373 !important;
  font-weight: bold !important;
  margin-left: 15px;
  top: 10px;
  position: relative;
}
.modal-dialog-centered {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center !important;
  justify-content: center;
  min-height: calc(100% - 1rem);
}
.title-heads {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
}

.reschedule-btn {
  background: #224373;
  border-radius: 5px !important;
  padding: 5px 13px !important;
  color: white;
  cursor: pointer;
}
.upload-disabled,
.upload-enabled {
  background-color: #748194;
  width: 118px;
  height: 41px;
  border-radius: 5px !important;
}
.upload-enabled {
  background: #224373;
}
.divRightChat_Text {
  span {
    color: white !important;
  }
}
.putback-submit {
  background: #224373;
  width: 118px;
  height: 41px;
  border-radius: 5px !important;
  color: #ffffff;
}
.btn__right {
  float: right;
}
.submit {
  background-color: #224373;
  color: #ffffff;
  &:disabled {
    background-color: lighten(#224373, 20%);
  }
}
.upload-doc-ip {
  float: left;
  width: 100%;
  height: 50px;
  font-weight: bold;
  color: #224373;
  ::placeholder {
    color: #224373 !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }
}
.custom-file-label::after {
  background-color: #224373 !important;
  color: #ffffff !important;
}
.reassigned-container {
  background-color: #ffffff;
  padding: 0 20px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
  flex: 2 1;
}
.mob-none {
  display: block;
}
.mob-display {
  display: none !important;
}
.input-height-set {
  input,
  select {
    height: 55px !important;
  }
}
#panZoom {
  max-height: 600px !important;
  overflow: initial !important;
}
.healthacard-headings {
  padding: 0 10px;
}
.hr-class {
  left: -40px;
  position: relative;
  width: 90%;
}
.consultation-city {
  margin-left: 0;
}
.prescriptions-form {
  margin-top: 55px;
}
.input__auto__search {
  height: 52px;
  padding: 0 10px;
  width: 215%;
  margin-left: 50px;
  background-color: #ffffff;
  border: 1px solid #d8e2ef;
}

.mode__left {
  left: -2.5px;
  position: relative;
}
.reason__right {
  position: relative;
  left: 2.5px;
}
.modal-chatNotes {
  top: 90px;
  position: relative;
  z-index: 9999;
  overflow-y: auto;
}
.chat-container {
  display: flex;
  align-items: center;
}
.from-provider,
.from-member {
  max-width: 250px;
  background-color: #224373;
  color: #ffffff;
  min-height: 50px !important;
  border-radius: 20px;
  padding: 10px;
  word-wrap: break-word;

  justify-content: flex-end;
}
.from-member {
  background-color: rgb(237, 235, 235);
  color: #224373;
  justify-content: start;
}
.from-userName {
  font-size: 0.7rem;
  color: #224373;
  margin-left: 8px;
  margin-bottom: 10px;
}
.chat-end {
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.chat-start {
  justify-content: start;
  flex-direction: column;
  align-items: start;
}
.chat-meta {
  display: flex;
}
.no-records {
  position: relative;
  top: -15px;
  padding-bottom: -10px !important;
  font-size: 14px;
  font-weight: 400;
}
.timeline__link__flex {
  img {
    top: -6px !important;
    position: relative;
    transform: scale(0.7);
  }
}
.html__title__wrapper {
  width: 100%;
  height: 50px;
  background-color: #66c0ff;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}
.html__title {
  position: relative;
  padding: 20px;
  color: #ffffff;
  font-weight: 700;
  h4,
  h6 {
    color: #ffffff;
  }
}
.modal__header__jump {
  display: flex;
  align-items: center;
  gap: 15px;
}
.confirm__old__new {
  padding: 10px;
  p {
    font-size: 16px;
  }
}
.confirm__old__new__btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 50px;
  button {
    border: none;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  .confirm__yes {
    background-color: #224373;
    color: #ffffff;
  }
  .confirm__no {
    background-color: #d8e2ef;
    color: #224373;
  }
}
.section__container {
  width: 100%;
}

.caseNotes__title {
  height: 50px;
  background-color: #d9eeff;
  color: #224373;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.caseNotes__add {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  button {
    border: none;
    background-color: #ffffff;
    color: #1ca8ff;
    cursor: pointer;
  }
}
.casenotes__input {
  input {
    width: 90%;
    height: 50px;
    border: 1px solid #9aaac161;
    border-radius: 5px;
    margin: 10px 0 10px 20px;
    padding-left: 10px;
    outline: none;
    &::placeholder {
      color: #9aaac1;
    }
    &:disabled {
      background-color: rgb(228, 227, 227);
      cursor: not-allowed;
      font-weight: 600;
    }
  }
  textarea {
    resize: none;
    border: 1px solid #9aaac161;
    border-radius: 5px;
    margin: 10px auto 10px 20px;
    padding: 10px;
    outline: none;
    width: 90%;
    &::placeholder {
      color: #9aaac1;
    }
    &:disabled {
      background-color: rgb(228, 227, 227);
      cursor: not-allowed;
    }
  }
  svg {
    margin-right: 20px;
    color: #224373;
    font-size: 20px;
    cursor: pointer;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    width: 20px !important;
  }
}
.checked__recomend {
  margin-top: -25px !important;
  label {
    // left: 5px;
    color: #224373 !important;
    position: relative;
    padding: 5px 0;
    overflow: hidden;
  }
}
.flex__initial {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.clollapsibleIcon {
  cursor: pointer;
  transform: scale(1.3);
}
.green__bg {
  background-color: #7eca9c !important;
}
.section__item__title {
  background-color: #009fbd;
  padding: 10px;
  color: #fff;
  // display: flex;
  // align-items: center;
  // gap: 10px;
  // cursor: pointer;
  border-top: 1px solid #dbdbdb;
}

.section__item__title__col {
  background-color: #224373;
  padding: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-top: 1px solid #dbdbdb;
}
.expand__icons {
  font-weight: bold;
}
.section__single__container {
  position: relative;
  // margin-top: 10px;
  color: #224373;
  h4,
  h5 {
    margin-left: 20px !important;
    font-weight: 400 !important;
    position: relative;
    // top: 5px;
  }
}
.last__session__form {
  top: 20px;
  position: relative;
  margin-bottom: 20px;
}

.flex__casenotes {
  display: flex;
  align-items: center;
}
.case__dob {
  width: 47%;
}
.client__details__container {
  position: relative;
  margin-left: 10px;
  h5 {
    font-size: 16px;
    position: relative;
    top: 5px;
  }
}
.casenotes__input,
.last__session__form {
  h4 {
    font-size: 16px;
    position: relative;
    top: 5px;
  }
}
.last__session__form {
  top: 20px;
  position: relative;
  margin-bottom: 20px;
}
.jump__to {
  position: fixed;
  margin-left: 70%;
  margin-top: 0px;
  z-index: 9;
}
.icons__jump {
  display: flex;
  padding: 10px;
  img {
    cursor: pointer;
  }
}
.sub__container__casenotes {
  margin: 10px 0;
}
.radio__container {
  margin: 10px 35px;
  display: flex;
  gap: 30px;
  input {
    margin-right: 5px;
  }
  input[type='radio'] {
    margin: 5px;
    height: 15px;
  }
}
.mrb__20 {
  margin-top: 15px;
}
.previous__session__titile {
  background-color: #f79327 !important;
}
.title__flex {
  display: flex;
  align-items: center;
}
.previous__session__container {
  display: flex;
  gap: 20px;
  margin-left: 10px;
  flex-wrap: wrap;
  small {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
  }
}
.consult__history__text {
  font-size: 14px;
}
.radio__item {
  display: flex;
}
.mental__end__start {
  display: flex;
  width: 93%;
}
.modal__header__jump {
  display: flex;
  align-items: center;
  gap: 15px;
}
.key__issues__flex {
  display: flex;
  flex-direction: column;
  margin-top: 20px !important;
  margin-left: 25px;
  input {
    margin-right: 5px;
  }
  h4 {
    top: -18px;
    left: -25px;
  }
  ul {
    position: relative;
    margin: 0 20px 30px 30px;
  }
}
.jump__too__main {
  position: sticky;
  top: 5px;
  z-index: 9999;
}
.sticky {
  position: sticky;
  top: 50px;
  z-index: 1;
  /* Add any other styles you want for sticky sections */
}
.common__btn {
  width: 220px;
  height: 50px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 15px;
  margin: 10px;
  font-weight: 700;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .view__link {
    font-size: 13px !important;
  }
  .feedback__avatar {
    margin-left: 55px;
  }
  .consultation-city {
    margin-left: 20px !important;
  }
  .input__auto__search {
    width: 97%;
    margin-left: 22px;
  }
  .hr-class {
    left: 0px !important;
  }
  .prescription__save {
    float: left;
    // margin-bottom: 1em;
  }
  .confirm {
    margin-left: -10px !important;
  }
  .btns-right {
    float: none;
    margin-left: -15px;
  }
  .btns-rights {
    left: 0px !important;
  }
  .btns {
    width: 120px;
    margin-top: 9px !important;
  }
  .btnConsultation {
    padding: 2px;
    width: 100px !important;
  }

  .disabled {
    cursor: not-allowed;
  }
  .btns-left {
    margin-left: -5px !important;
  }
  .mob-mrt {
    top: -45px;
  }
  .upload-buttons {
    position: relative;
    margin-top: 0px !important;
  }
  .history-items {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .uploaded-documents {
    width: 91% !important;
    margin: 20px auto !important;
    p {
      margin-top: 10px !important;
    }
  }
  .doc-hr {
    top: -20px !important;
    position: relative;
  }
  .consultation-history {
    width: 90% !important;
    margin: 0px 20px auto !important;
    margin-bottom: 20px;
  }

  .display-new {
    flex-direction: column;
  }
  .left-mv {
    margin-left: 0 !important;
  }

  .card-container {
    width: auto;
    height: auto;
    margin: 0 10px;
    margin-bottom: 10px;
  }
  .consultation-avatar {
    width: 150px;
  }
  .consultation-patient {
    margin-left: -65px !important;
    h3 {
      font-size: 24px !important;
    }
  }
  .detail-info {
    margin-bottom: -5px !important;
  }
  .consultation-container {
    width: 330px !important;
  }
  .divWait {
    margin: 0px 30px !important;
  }
  .healthcare-card {
    margin-bottom: 15px !important;
  }
  .btns-new {
    left: 0 !important;
  }
  .follow-ups-container {
    margin: 20px auto;

    width: 92% !important;
    margin-bottom: 25px;
  }
  .reassigned-container {
    padding: 0 10px !important;
  }
  .mob-none {
    display: none;
  }
  .mob-display {
    display: block !important;
  }
  .divHeader_pres {
    width: 94% !important;
    border-radius: 10px 10px 0 0;
    position: fixed !important;
  }
  .doc-hr {
    top: -4px;
  }
  .left-join {
    left: -100px !important;
    position: relative;
  }
  .chat_modal {
    height: 450px !important;
    .modal-content {
      height: 450px !important;
    }
  }
  .cancel-consultation {
    left: 0px !important;
    position: relative;
  }
  p {
    margin-bottom: 25px;
  }
  .mrt--20 {
    top: -35px;
    position: relative;
  }
  .mrt--40 {
    top: -35px;
  }
  .mrb--40 {
    margin-bottom: -20px !important;
    position: relative;
  }
  .mrt--010 {
    top: -25px;
    position: relative;
  }
  .timeline__link__flex {
    img {
      top: -10px !important;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .consultation-container {
    width: 700px !important;
    padding: 10px !important;
    margin-top: 20px !important;
  }
  .left-mv {
    margin-left: 0 !important;
  }
  .btns-left {
    margin-left: 0 !important;
  }
  .healthcare-card {
    height: 400px !important;
  }
  .display-new {
    display: block !important;
  }
  .consultation-history,
  .uploaded-documents {
    width: 700px !important;
    margin-bottom: 20px !important;
  }
  .card-container {
    width: 700px !important;
  }
  .btns-new {
    left: 0px !important;
  }
  .left-join {
    left: 0px;
    position: relative;
    margin-top: 5px;
  }
  .mrt--010 {
    top: -20px;
    position: relative;
  }
}
