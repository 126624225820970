#ag-canvas {
  height: 100%;
  border-radius: 10px;
  background: #373434 !important;
  /* min-height: 445px !important;	
  max-height: 445px !important;	 */
}

.ag-item :first-child {
  border-radius: 6px;
  z-index: 1;
}

.ag-item {
  background-size: contain;
  background-image: url(https://storage.merago.com/avatar/user-1.png) !important;
  min-height: 100px !important;
  background-repeat: no-repeat;
  /* background: black !important; */
  border-radius: 6px;
  background-position: center;
  height: 100%;
  background-size: 100px 100px;
  width: 100%;
  padding: 0 !important;
}

#ag-resize-container {
  background-image: none !important;
  display: flex;
  justify-content: center;
  height: center;
}

#ag-resize-container .ag-item {
  border: none !important;
}

/* button group */
.ag-btn-group {
  height: 70px;
  width: calc(100% - 2px);
  margin: -5px auto;
  position: absolute;
  bottom: 6px;
  z-index: 2;
  display: flex;
  opacity: 0.8;
  justify-content: space-around;
  align-items: center;
  background: #474747;
  border-radius: 0 0 6px 6px;
}
.ag-btn {
  z-index: 12;
  opacity: 0;
  color: white;
  opacity: 1;
  /* width: 35px;	
    height: 35px; */
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ag-btn.off.videoControlBtn .ag-icon-camera {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera-off {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera {
  display: inline-block;
}

.ag-btn.off.videoControlBtn .ag-icon-camera-off {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic-off {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic-off {
  display: inline-block;
}
