/* Generated by grunt-webfont */
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
} /* Firefox */
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
} /* Webkit */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
} /* IE */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
} /* Opera */

.blink {
  text-align: center;
  margin-bottom: 4px;

  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 1.5s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 1.5s infinite ease-in-out; /* IE */
  animation: blink normal 1.5s infinite ease-in-out; /* Opera */
}

@font-face {
  font-family: 'icons';
  src: url('../fonts/icons.eot?7aedd013e072110dcc3400637dd0f69d');
  src: url('../fonts/icons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/icons.woff?7aedd013e072110dcc3400637dd0f69d') format('woff'),
    url('../fonts/icons.ttf?7aedd013e072110dcc3400637dd0f69d')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.ag-icon {
  font-family: 'icons';

  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Icons */

.ag-icon-arrow-down:before {
  content: '\f101';
}

.ag-icon-audience:before {
  content: '\f102';
}

.ag-icon-ss:before {
  content: '\f1e7';
}
.ag-icon-audio:before {
  content: '\f103';
}
.ag-icon-chat:before {
  content: '\f27a';
}

.ag-icon-camera-off:before {
  content: '\f104';
}
.ag-icon-camera1-off:before {
  content: '\f104';
  font-size: 34px;
}

.ag-icon-camera:before {
  content: '\f105';
}

.ag-icon-disable-video:before {
  content: '\f106';
}

.ag-icon-invalid:before {
  content: '\f107';
}

.ag-icon-leave:before {
  content: '\f108';
}
.ag-icon-join:before {
  content: '\f108';
  background: url(https://images.vexels.com/media/users/3/137415/isolated/preview/0e475bb…-telephone-call-icon-logo-by-vexels.png);
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.ag-icon-mic-off:before {
  content: '\f109';
}

.ag-icon-mic:before {
  content: '\f10a';
}

.ag-icon-remove-pip:before {
  content: '\f10b';
}

.ag-icon-screen-share:before {
  content: '\f10c';
}

.ag-icon-switch-display:before {
  content: '\f10d';
}

.ag-icon-valid:before {
  content: '\f10e';
}
