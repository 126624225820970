.merago_SAF_img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.professional__add-other {
  margin: '-20px 0 20px 0' !important;
  display: 'flex' !important;
  align-items: 'center' !important;
  justify-content: 'flex-end' !important;
}

@media screen and (orientation: portrait) {
  .inner-container {
    top: 13vh;
  }
}

@media screen and (orientation: landscape) {
  .inner-container {
    top: 15vh;
  }
}

@media (max-width: 760px) {
  .patient-details-mobile-view {
    text-align: center !important ;
  }
  a {
    font-size: 11px;
  }
  .serviceInfo__add-clinic-cascade {
    padding-left: 0;
    padding-right: 0;
  }
  .serviceinfo__add-clinic-btns {
    text-align: center !important;
  }
  .professional__add-other {
    margin: '-10px 0 20px 0' !important;
    display: 'flex' !important;
    align-items: 'center' !important;
    justify-content: 'flex-end' !important;
  }
  .professional__qual-doc {
    padding-left: 1px;
    padding-right: 1px;
    top: 10px;
    position: relative;
  }
  .header-consultation-details .first-header {
    border-right: none;
    border-bottom: 1px solid #ced6e1;
    margin-bottom: 1rem;
    line-height: 10px;
    height: 70px;
    text-align: center;
  }
  .time-frst-hdr {
    padding-bottom: 5px;
    margin-bottom: 1rem;
  }

  .health-tab-4 {
  }
}
.btn-block-custom {
  border-radius: 8px !important;
  position: relative;
  right: 20px;
}

@media all and (max-width: 480px) {
  .nav-tabs .nav-item {
    margin-left: 2rem;
    font-size: 12px;
  }
  .btn-block-custom {
    border-radius: 8px !important;
  }
  .btnConfirmDiv {
    margin-left: 0px;
    margin-top: 0.25rem;
  }
}
@media (min-width: 576px) {
  .content {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    margin-top: 18%;
    font-size: 16px !important;
    font-family: Oxygen;
  }

  .cardHeaderTitle {
    font-size: 14px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 24px;
  }
  .dashboard-filter {
    float: right;
  }
  .subjectRead {
    padding-left: 0.5rem;
    font-size: 14px;
  }
  .fromPatientUnReadMessage {
    padding-left: 0.5rem;
    padding-top: 0.5rem;
  }
  .fromPatientUnReadMessage {
    padding-left: 0.5rem;
    padding-top: 0.5rem;
  }
}
@media (min-width: 768px) {
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 22px;
  }
  .cardHeaderTitle {
    font-size: 14px;
  }
  .dashboard-filter {
    float: right;
  }
  .check-box-left-m1 {
    margin-left: 1.25em !important;
  }
}
@media (min-width: 992px) {
  h2 {
    font-size: 32px;
  }
  .cardHeaderTitle {
    font-size: 14px;
  }
  .dashboard-filter {
    float: right;
  }
  h3 {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 32px;
  }
  .dashboard-filter {
    float: right;
  }
  .cardHeaderTitle {
    font-size: 14px;
  }
  h3 {
    font-size: 24px;
  }
  .divFloatingActivieButtonMobile {
    margin-top: 160px;
  }
  .content {
    /* margin-left: 10rem;
    margin-right: 10rem; */
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    margin-bottom: 0rem;
    margin-top: 70px;
    font-size: 16px !important;
    font-family: Oxygen;
  }
}
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #3f6afb !important;
}
.txtUserName {
  min-width: 60% !important;
}
.MuiStepper-horizontal {
  background: transparent !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
  .MuiFab-sizeSmall {
    width: 30px !important;
    height: 30px !important;
  }
  .seeAll {
    float: right;
    margin: 6px;
    /* margin-left: 53%; */
  }
  .w86 {
    width: 80% !important;
  }
  .header-consultation-details .first-header {
    margin: 0 !important;
  }
  .imgClose {
    filter: grayscale(181%) contrast(105%) blur(0px) sepia(51%) brightness(100%)
      hue-rotate(120deg) invert(0) saturate(70) opacity(9.5);
  }
  .divModalContent_prescription > div {
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
  }
  .btnPrescription {
    width: 96% !important;
    padding: 23px !important;
    margin-bottom: 25px !important;
  }
  .content {
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 15px !important;
    /* margin-top: 18%; */
  }

  .divCommonGrid {
    overflow-x: hidden !important;
  }
  .meeting .ag-header {
    top: calc(100vh - 487px) !important;
  }
  .divAudioVideoIcons,
  .divAudioVideoIcons2,
  .ag-item-label {
    display: none !important;
  }
  /* video{
      height:100vh !important;
    } */
  .remotee > div > video {
    height: auto !important;
  }
  .ag-btn > img {
    width: 40px !important;
  }
  .ag-btn > i,
  .ag-btn.audioControlBtn .ag-icon-mic-off,
  .ag-icon-camera-off {
    font-size: 20px !important;
  }

  element.style {
    margin: 0;
    padding: 0;
  }
  .ag-btn-group {
    /* margin-left: -15px !important; */
    width: -webkit-fill-available;
  }
  .divLogo {
    height: 70px !important;
    margin-left: 0 !important;
  }
  .gridBottom {
    padding-bottom: 25px !important;
  }
  .MuiGrid-grid-sm-6 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .divRightGrid,
  .topStepper {
    display: none !important;
  }
  /* .dialogChat {
    top: calc(100vh - 690px) !important;
  } */
  .dialogChat-2 {
    top: 5px !important;
    position: relative;
    margin: auto;
  }
  .chat_converse {
    /* height: 460px !important; */
    height: 400px !important;
    overflow-x: hidden;
  }

  .btnHeader {
    align-items: center !important;
    /* background-color: var(--royal-blue) !important; */
    border-radius: 8px !important;
    cursor: pointer !important;
    /* display: flex !important; */
    height: 40px !important;
    float: right;
    margin-top: 24px !important;
    max-width: 100% !important;
    color: white;
    text-align: center;
    min-width: 100%;
  }

  h5 {
    font-size: 10px !important;
  }
  .p0M {
    padding: 0 !important;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 13px !important;
    padding: 8px !important;
  }
  .btnCommon {
    min-width: 100% !important;
    margin-left: 0 !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .MuiGrid-item {
    margin: 0 !important;
  }
  .divBottomText0 {
    font-size: 23px !important;
    top: calc(100vh - 280px) !important;
  }
  #divLoginRight,
  #divIntroRight,
  #divRegisterRight,
  #divVerifyRight {
    display: none;
  }
}

/* Smartphones (landscape) ----------- */

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */

  .divLogo {
    height: 70px !important;
    margin-left: 0 !important;
  }
  .MuiGrid-grid-sm-6 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .divRightGrid,
  .topStepper {
    display: none !important;
  }
  .dialogChat {
    top: calc(100vh - 690px) !important;
  }
  .chat_converse {
    height: 200px !important;
  }

  .btnHeader {
    align-items: center !important;
    /* background-color: var(--royal-blue) !important; */
    border-radius: 8px !important;
    cursor: pointer !important;
    /* display: flex !important; */
    height: 40px !important;
    float: right;
    margin-top: 24px !important;
    max-width: 100% !important;
    color: white;
    text-align: center;
    min-width: 100%;
  }

  h5 {
    font-size: 10px !important;
  }
  .p0M {
    padding: 0 !important;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 13px !important;
    padding: 8px !important;
  }
  .btnCommon {
    min-width: 100% !important;
    margin-left: 0 !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .navbar-light .navbar-nav .nav-link {
    font-size: 15px !important;
  }
  .divCenter {
    align-items: initial !important;
  }
  .ag-btn-group {
    /* margin-left: 30px !important; */
    width: 100% !important;
  }
  .divRightGrid,
  .topStepper {
    display: block !important;
  }
  #divLoginRight,
  #divIntroRight,
  #divRegisterRight,
  #divVerifyRight {
    display: block;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .divCenter {
    align-items: initial !important;
  }
  .divRightGrid,
  .topStepper {
    display: block !important;
  }
  #divLoginRight,
  #divIntroRight,
  #divRegisterRight,
  #divVerifyRight {
    display: block;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  .divCenter {
    align-items: initial !important;
  }
  .gridBottom {
    padding-bottom: 25px !important;
  }
  .divMobileStepper {
    margin-left: 12% !important;
  }
  .divLogo {
    margin-top: 100px !important;
    margin-left: 0 !important;
  }
  h1 {
    font-size: 20px !important;
  }
  p {
    font-size: 12px !important;
  }
  .divRightGrid,
  .topStepper {
    display: block !important;
  }
  #divLoginRight,
  #divIntroRight,
  #divRegisterRight,
  #divVerifyRight {
    display: block;
  }
  .upload-imgs {
    /* left: 130px !important; */
  }
}
/**********
    iPad 3
    **********/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  .divCenter {
    align-items: initial !important;
  }
  .divLogo {
    margin-top: 100px !important;
    margin-left: 0 !important;
  }
  .gridBottom {
    padding-bottom: 25px !important;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
  .divTableBody {
    font-size: 14px;
  }
  #ag-canvas {
    min-height: 455px !important;
    height: auto;
  }
  .ag-item-label-audioOnly {
    font-size: 50px !important;
  }
  .navbar-light .navbar-nav .nav-link {
    font-family: Oxygen;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #9aaac1;
  }
  .gridBottomFixed {
    height: 0 !important;
  }
  .divLogo {
    margin-left: 15px !important;
  }
  div.carousel-item > img {
    width: 100%;
    object-fit: contain !important;
    display: flex;

    flex-flow: column wrap;
    justify-content: center;
  }
  .gridBottom {
    display: none !important;
  }
  .topStepper {
    margin-left: 80px !important;
    max-width: 80% !important;
  }
  .MuiButton-containedPrimary {
    max-width: fit-content !important;
  }
  .padding10 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .padding30 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .divRightGrid,
  .topStepper {
    display: block !important;
  }
  .divBottomText0 {
    top: calc(100vh - 210px) !important;
  }
  #divConfirmed {
    background-size: cover !important;
  }
  #divLoginRight {
    height: auto !important;
  }
  .divBottomText0 {
    font-size: 30px !important;
  }

  .divMain2 {
    background: white !important;
    height: 100vh;
  }
  .divCenter {
    display: block !important;
    margin-left: 50px !important;
    align-items: center;
    justify-content: center;
  }
  #divLoginRight,
  #divIntroRight,
  #divRegisterRight,
  #divVerifyRight {
    display: block;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
  .navbar-light .navbar-nav .nav-link {
    font-family: Oxygen;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #9aaac1;
  }
  .addUserIcon {
    margin-top: -5em;
  }
  .divCenter {
    display: block !important;
    margin-left: 50px !important;
    align-items: center;
    justify-content: center;
  }
  .gridBottomFixed {
    height: 0 !important;
  }
  .divLogo {
    margin-left: 10px !important;
  }
  div.carousel-item > img {
    width: 100%;
    object-fit: contain !important;
    display: flex;

    flex-flow: column wrap;
    justify-content: center;
  }
  .gridBottom {
    display: none !important;
  }
  .topStepper {
    margin-left: 30px !important;
  }
  .MuiButton-containedPrimary {
    max-width: fit-content !important;
  }
  .divRightGrid,
  .topStepper {
    display: block !important;
  }
  #divLoginRight {
    height: auto !important;
  }
  #divConfirmed {
    background-size: cover !important;
  }
  .divBottomText0 {
    top: calc(100vh - 260px) !important;
  }
  .divMain2 {
    background: white !important;
    height: 100vh;
  }
  #divLoginRight,
  #divIntroRight,
  #divRegisterRight,
  #divVerifyRight {
    display: block;
  }
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  .divCenter {
    align-items: initial !important;
  }
  .gridBottomFixed {
    height: 20vh !important;
  }
  .divRightGrid,
  .topStepper {
    display: none !important;
  }
  .dialogChat {
    top: calc(100vh - 690px) !important;
  }
  .chat_converse {
    height: 200px !important;
  }

  .btnHeader {
    align-items: center !important;
    /* background-color: var(--royal-blue) !important; */
    border-radius: 8px !important;
    cursor: pointer !important;
    /* display: flex !important; */
    height: 40px !important;
    float: right;
    margin-top: 24px !important;
    max-width: 100% !important;
    color: white;
    text-align: center;
    min-width: 100%;
  }

  h5 {
    font-size: 10px !important;
  }
  .p0M {
    padding: 0 !important;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 13px !important;
    padding: 8px !important;
  }
  .btnCommon {
    min-width: 100% !important;
    margin-left: 0 !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .gridBottom {
    padding-bottom: 25px !important;
  }
  .gridBottom {
    padding-bottom: 25px !important;
  }
  h1 {
    font-size: 20px !important;
    line-height: 27px !important;
  }
  .divLogo {
    margin-left: 10px !important;
    margin-top: 5px !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  video {
    max-height: 300px !important;
  }
  .remotee > div > video {
    width: 160px !important;
    height: 120px !important;
    object-fit: contain !important;
    margin-right: 19px !important;
  }
  h1 {
    font-size: 20px !important;
    line-height: 27px !important;
  }
  .divLogo {
    margin-left: 10px !important;
    margin-top: 5px !important;
  }
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
  .divCenter {
    align-items: initial !important;
  }
  .seeAll {
    float: right;
    margin: 6px;
    margin-left: 53%;
  }
  .w86 {
    width: 80% !important;
  }
  .header-consultation-details .first-header {
    margin: 0 !important;
  }
  .imgClose {
    filter: grayscale(181%) contrast(105%) blur(0px) sepia(51%) brightness(100%)
      hue-rotate(120deg) invert(0) saturate(70) opacity(9.5);
  }
  .divModalContent_prescription > div {
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
  }
  .btnPrescription {
    width: 96% !important;
    padding: 23px !important;
    margin-bottom: 25px !important;
  }
  .content {
    margin: 15px !important;
  }
  .gridBottomFixed {
    height: 20vh !important;
  }
  .divRightGrid,
  .topStepper {
    display: none !important;
  }
  .dialogChat {
    top: calc(100vh - 690px) !important;
  }
  .chat_converse {
    height: 200px !important;
  }

  .btnHeader {
    align-items: center !important;
    /* background-color: var(--royal-blue) !important; */
    border-radius: 8px !important;
    cursor: pointer !important;
    /* display: flex !important; */
    height: 40px !important;
    float: right;
    margin-top: 24px !important;
    max-width: 100% !important;
    color: white;
    text-align: center;
    min-width: 100%;
  }

  h5 {
    font-size: 10px !important;
  }
  .p0M {
    padding: 0 !important;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 13px !important;
    padding: 8px !important;
  }
  .btnCommon {
    min-width: 100% !important;
    margin-left: 0 !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .gridBottom {
    padding-bottom: 25px !important;
  }
  h1 {
    font-size: 20px !important;
    line-height: 27px !important;
  }
  .divLogo {
    margin-left: 0 !important;
    margin-top: 5px !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */

  .content {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .container-fluid {
    padding-right: 2px !important;
    padding-left: 6px !important;
  }
  video {
    max-height: 300px !important;
  }
  h1 {
    font-size: 20px !important;
    line-height: 27px !important;
  }
  .divLogo {
    margin-left: 0 !important;
    margin-top: 5px !important;
  }
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
  video {
    max-height: 300px !important;
  }
  .ag-btn > img {
    width: 40px !important;
  }
  .ag-btn > i,
  .ag-btn.audioControlBtn .ag-icon-mic-off,
  .ag-icon-camera-off {
    font-size: 20px !important;
  }

  element.style {
    margin: 0;
    padding: 0;
  }
  .ag-btn-group {
    /* margin-left: -15px !important; */
    width: -webkit-fill-available;
  }
  .divCenter {
    align-items: initial !important;
  }
  .seeAll {
    float: right;
    margin: 6px;
    margin-left: 53%;
  }
  .w86 {
    width: 80% !important;
  }
  .header-consultation-details .first-header {
    margin: 0 !important;
  }
  .imgClose {
    filter: grayscale(181%) contrast(105%) blur(0px) sepia(51%) brightness(100%)
      hue-rotate(120deg) invert(0) saturate(70) opacity(9.5);
  }
  .divModalContent_prescription > div {
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
  }
  .btnPrescription {
    width: 96% !important;
    padding: 23px !important;
    margin-bottom: 25px !important;
  }
  .content {
    margin: 15px !important;
  }
  .gridBottomFixed {
    height: 20vh !important;
  }
  .divRightGrid,
  .topStepper {
    display: none !important;
  }
  .dialogChat {
    top: calc(100vh - 690px) !important;
  }
  .chat_converse {
    height: 200px !important;
  }

  .btnHeader {
    align-items: center !important;
    /* background-color: var(--royal-blue) !important; */
    border-radius: 8px !important;
    cursor: pointer !important;
    /* display: flex !important; */
    height: 40px !important;
    float: right;
    margin-top: 24px !important;
    max-width: 100% !important;
    color: white;
    text-align: center;
    min-width: 100%;
  }

  h5 {
    font-size: 10px !important;
  }
  .p0M {
    padding: 0 !important;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 13px !important;
    padding: 8px !important;
  }
  .btnCommon {
    min-width: 100% !important;
    margin-left: 0 !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .gridBottom {
    padding-bottom: 25px !important;
  }
  h1 {
    font-size: 20px !important;
    line-height: 27px !important;
  }
  .divLogo {
    margin-left: 0 !important;
    margin-top: 5px !important;
  }

  .MuiGrid-grid-sm-6 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */

  .content {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .container-fluid {
    padding-right: 2px !important;
    padding-left: 6px !important;
  }
  video {
    max-height: 300px !important;
  }
  .ag-btn > img {
    width: 40px !important;
  }
  .ag-btn > i,
  .ag-btn.audioControlBtn .ag-icon-mic-off,
  .ag-icon-camera-off {
    font-size: 20px !important;
  }

  element.style {
    margin: 0;
    padding: 0;
  }
  .ag-btn-group {
    /* margin-left: -15px !important; */
    width: -webkit-fill-available;
  }
  h1 {
    font-size: 20px !important;
    line-height: 27px !important;
  }
  .divLogo {
    margin-left: 0 !important;
    margin-top: 5px !important;
  }
}

/* iPhone 6+, 7+, 8+ ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
  .MuiGrid-grid-sm-6 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
  .ag-btn > img {
    width: 40px !important;
  }
  .ag-btn > i,
  .ag-btn.audioControlBtn .ag-icon-mic-off,
  .ag-icon-camera-off {
    font-size: 20px !important;
  }

  element.style {
    margin: 0;
    padding: 0;
  }
  .ag-btn-group {
    /* margin-left: -15px !important; */
    width: -webkit-fill-available;
  }
  .divCenter {
    align-items: initial !important;
  }
  .gridBottomFixed {
    height: 20vh !important;
  }
  .divRightGrid,
  .topStepper {
    display: none !important;
  }
  .dialogChat {
    top: calc(100vh - 690px) !important;
  }
  .chat_converse {
    height: 200px !important;
  }

  .btnHeader {
    align-items: center !important;
    /* background-color: var(--royal-blue) !important; */
    border-radius: 8px !important;
    cursor: pointer !important;
    /* display: flex !important; */
    height: 40px !important;
    float: right;
    margin-top: 24px !important;
    max-width: 100% !important;
    color: white;
    text-align: center;
    min-width: 100%;
  }

  h5 {
    font-size: 10px !important;
  }
  .p0M {
    padding: 0 !important;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 13px !important;
    padding: 8px !important;
  }
  .btnCommon {
    min-width: 100% !important;
    margin-left: 0 !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .gridBottom {
    padding-bottom: 25px !important;
  }
}

/* #appointmentImage {
  background-image: url('assets/img/slider/appointment.png');
  background-repeat: no-repeat;
  height: 40vh;
  background-size: contain;
  margin-left: -17px;
 padding: 2em; 
  margin-bottom: 11px;
} */
#img2 {
  background-image: url('assets/img/Consultation.png');
  height: 117px;
  background-size: cover;
  /* padding: 2em; */
  margin: -1em;
}
#divLoginRight {
  background-image: url('assets/img/rightBackground-1.png');
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  height: 100vh;
}
.btnNextSlide {
  position: absolute !important;
  height: 40px !important;
  /* top: 637px !important; */
  font-family: 'Oxygen', Helvetica !important;
  font-family: var(--font-family-oxygen) !important;
  font-size: 16px !important;
  font-size: var(--font-size-l) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  /* left: 450px !important; */
  display: flex !important;
  padding: 0px 59.5px !important;
  -webkit-box-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  min-width: 160px !important;
  background-color: var(--royal-blue) !important;
  border-radius: 8px !important;
  mix-blend-mode: normal !important;
  cursor: pointer !important;
  color: white !important;
  bottom: 30px !important;
}
.topStepper {
  position: absolute;
  top: 12px;
}
div.carousel-item > img {
  /* width: 100%;
    object-fit: contain !important; */
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  object-fit: cover !important;
}
.MuiMobileStepper-root {
  background: #ffffff !important;
}
.MuiMobileStepper-dots {
  margin-left: 67px !important;
}
.divMobileStepper {
  width: fit-content;
  margin-left: 20%;
  max-width: 400px;
  position: absolute;
  bottom: 20px;
}
#divIntroRight {
}
#divRegisterRight {
  /* background-image: url('assets/img/slider/slider2.png');
  background-size: 100% 100%; */
}
#divVerifyRight {
  /* background-image: url('assets/img/slider/slider4.png');
  background-size: 100% 100%; */
}
#divConfirmed {
  /* background-image: url('assets/img/slider/slider5.png');
  background-size: 100% 100%; */
}
.divLogo {
  background-repeat: no-repeat;
  background-size: auto;
  margin-top: 20px !important;
  height: 66px;
  width: 55px;
  background-image: url('assets/meragoLogo.svg');
}
.btnVerify {
  background-color: var(--royal-blue) !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  display: flex !important;
  height: 40px !important;
}

.divBottomText {
  margin-left: 16%;
}
.btnReturnLogin {
  height: 40px !important;
  font-family: 'Oxygen', Helvetica !important;
  font-family: var(--font-family-oxygen) !important;
  font-size: 16px !important;
  font-size: var(--font-size-l) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  width: fit-content;
  padding: 8px 24.5px !important;
  background-color: var(--royal-blue) !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  color: white !important;
}
.divBottomText0 {
  position: absolute;
  font-weight: 500;
  left: 0%;
  right: 0%;
  text-align: center;
  top: calc(100vh - 345px);
  color: var(--midnight-blue);
  font-family: 'Oxygen', Helvetica;
  font-family: var(--font-family-oxygen);
  font-style: normal;
  font-weight: 700;
}
.divBottomText2 {
  letter-spacing: 0;
  line-height: 40px;
  mix-blend-mode: normal;
  margin-left: 16%;
  position: absolute;
  text-align: center;
  width: 280px;
  top: calc(100vh - 20px) !important;
}
.divBottomText {
  letter-spacing: 0;
  line-height: 40px;
  mix-blend-mode: normal;
  position: absolute;
  text-align: center;
  width: 280px;
  top: calc(100vh - 140px) !important;
}
.divMain2 {
  background: white !important;
  height: 100vh;
}
.MuiMobileStepper-dots-right {
  position: absolute;
  left: calc(61vw);
  /* right: 14px; */
  bottom: 15px;
}
.divLogo {
  margin-left: 10px !important;
}
.divLeftGrid {
}
.divRightGridNew {
  background: #d8ecfb;
  /* border-radius: 15px; */
  border-radius: 0px 15px 15px 0px;
}

.padding10 {
  background: white !important;
}
/* .btnLogin{
        align-items: center !important;
        background-color: var(--royal-blue) !important;
        border-radius: 8px !important;
        margin-top: 20px !important;
        float:right !important;
      } */
.rightLabel {
  margin-bottom: 0px;
  margin-top: 7px;
  text-align: right;
  width: fit-content;
  float: right;
}
.leftLabel {
  margin-bottom: 7px;
  margin-top: 7px;
  width: fit-content;
  float: left;
}
.divSignup {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 264px);
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .card-list-table tbody tr {
    width: 89vw !important;
  }
  .divRightGridNew {
    background: #d8ecfb;
    border-radius: 15px;
  }
}
.gridBottom {
  bottom: 0;
  left: 0%;
  right: 0%;
}
.gridBottomFixed {
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  height: 0;
}
/* h1 {
        font-size: calc(20px + 0.6vw)!important;
      }
      h2 {
        font-size:calc(16px + 0.6vw)!important;
      }
      p {
        font-size: calc(12px + 0.6vw)!important;
      } */

.carousel-indicators li {
  border-radius: 11px !important;
  background: #3f6afb !important;
  /* width: 200px !important; */
  /* height: 200px !important; */
  border-radius: 50% !important;
  box-sizing: content-box !important;
  -webkit-box-flex: 0 !important;
  /* flex: 0 1 auto !important; */
  width: 13px !important;
  height: 13px !important;
  /* margin-right: 3px !important; */
  margin-left: 3px !important;
  /* text-indent: -999px !important; */
  cursor: pointer !important;
  border: 0 !important;
  transition: opacity 0.6s ease !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 4px !important;
  text-align: center !important;
  color: var(--nepal) !important;
  font-family: 'Oxygen', Helvetica !important;
  font-family: var(--font-family-oxygen) !important;
  font-style: normal !important;
  font-weight: 700 !important;
}
.divCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-caption {
  bottom: 270px !important;
}
.carousel-indicators {
  bottom: 250px !important;
}
.spqBtn {
  max-width: 320px !important;
  /* margin-right: 10px !important;
  font-size:12px !important; */
}
.react-datepicker-wrapper {
  width: 100%;
}
.addUserIcon {
  margin-top: -6em;
}
.btnCommon {
  background: #416bfb !important;
  color: white !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  font-family: Oxygen !important;
  font-style: normal !important;
}
.bg-light {
  background-color: #224373 !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #9aaac1 !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  font-family: Oxygen !important;
  font-style: normal !important;
  /* padding: 20px;
  font-size: 14px; */
}

.navbar-light .navbar-toggler-icon {
  -webkit-filter: grayscale(4) invert(1);
}
.active {
  /* border-bottom: 4px solid #ff4646 !important; */
}
.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 5 !important;
}
.NotificationCount {
  top: 1px !important;
  right: 30px !important;
}
.react-tel-input .form-control {
  height: 50px;
}
.divFooter {
  /* margin-top: 10%; */
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #99aac1;
  text-align: center;
  background: #224373;
  position: relative;
  right: 0;
  left: 0;
  /* z-index: 1030; */
  bottom: 0;
}

.mt2 {
  margin-top: 2em !important;
}
.react-tel-input .form-control {
}
.p0 {
  padding: 0 !important;
}
.ddlSelect {
  min-height: 50px !important;
}
.m0 {
  margin: 0 !important;
}
.m10 {
  margin-top: 10px !important;
}
.bgClear {
  background: #66c0ff !important;
  width: 170px;
  height: 50px;
  position: relative;
  padding-left: -20px;
  border-radius: 5px !important;
}
.txtInput {
  min-height: 50px !important;
}
.fc th,
.fc td {
  max-width: 100% !important;
  /* white-space: nowrap !important; */
}
.btnFile {
  background: #416bfb !important;
  color: white !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  font-family: Oxygen !important;
  font-style: normal !important;
  align-items: center !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  height: 40px !important;
  float: right;
  margin-top: 10px !important;
  min-width: 100% !important;
  text-align: center;
  border: #416bfb !important;
}
.faUpload {
  color: white !important;
  font-size: 13px !important;
}
.inpFileUploadRemove {
  /* margin-top: 2em; */
}
.group-pagination {
  /* display: inline-block !important; */
}
.fs-10 {
  font-size: 11px !important;
}
label {
  margin-bottom: 0 !important;
}
.cRed {
  filter: invert(37%) sepia(49%) saturate(2549%) hue-rotate(338deg)
    brightness(100%) contrast(99%) !important;
}
.delete {
  background: #c0392b;
}
.touch {
  transition: ease-in-out 0.2s;
  line-height: 40px;
  width: 40px;
  padding: 0px;
  text-align: center;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #4245a8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.w100 {
  width: 100% !important;
}
.otpInp > input {
  height: 45px !important;
  width: 45px !important;
  border: 1px solid #b0b3b7 !important;
}
.otpInp > span {
  padding: 10px !important;
}

.otpInpInvalid > input {
  height: 45px !important;
  width: 45px !important;
  border-color: red;
}
.otpInpInvalid > span {
  padding: 10px !important;
}

@media (min-width: 1200px) {
  .ag-icon {
    max-width: 70px !important;
  }
  .ag-btn-group > span,
  .ag-btn-group > div,
  .ag-btn-group > img {
    max-width: 120px !important;
    border-radius: 10px;
  }
}
.btnConsultation {
  align-items: center !important;
  border-radius: 8px !important;
  cursor: pointer;
  /* display: flex !important; */
  height: 40px !important;
  margin-left: 10px;
  float: left;
  /* margin-top: 24px !important; */
  color: white;
  text-align: center;
  width: fit-content;
}
.blue {
  background-color: var(--royal-blue);
}
.red {
  background-color: #ec7474;
}
.white {
  background-color: #fff;
}
.green {
  background-color: #548235 !important;
  color: white !important;
}
.grey {
  background-color: #748194 !important;
}

.spCard {
  background: #f5f7fa !important;
  border-radius: 9px !important;
}
.spXL > div > span {
  color: #224373 !important;
  font-family: var(--font-family-oxygen) !important;
  font-size: var(--font-size-xl) !important;
  font-style: normal !important;
  font-weight: 700 !important;
}
.overflowInitial {
  overflow: initial !important;
}

.custom-file-label {
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;
  white-space: nowrap;
  /* white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: -moz-fit-content !important; */
  /* width: fit-content !important; */
  /* display: flex !important; */
}
.div-AWAITING_RESPONSE {
  background-color: #40cdb4 !important;
  color: #ffffff !important;
}
.div-AWAITING_RESPONSE > div > span > p {
  color: #ffffff !important;
}
.div-COMPLETED,
.div-CONFIRMED {
  background-color: #ccf6e4 !important;
  color: #00864e !important;
}

.div-BLOCKED,
.div-BLOCKED {
  background-color: #efa3c8 !important;
  color: #00864e !important;
}

.div-COMPLETED > div > span > p,
.div-CONFIRMED > div > span > p {
  color: #00864e !important;
}
.div-CANCELLED {
  background-color: rgba(236, 116, 116, 0.3) !important;
  color: #ec7474 !important;
}
.div-REJECTED,
.div-DISCONNECTED {
  background-color: rgba(236, 116, 116, 0.3) !important;
  color: #ec7474 !important;
}

@media (hover: none), (hover: on-demand) {
  .trNoRows {
    width: -webkit-fill-available !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .tdNoRows {
    padding: 0 !important;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .react-datepicker__navigation-icon {
      position: unset !important;
      top: -9px !important;
    }
  }
}
.h4PresClass2 {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 13px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #5e6e82 !important;
}
.h4PresClass {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 15px !important;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  margin: 0 !important;
}
.divHeadPres {
  background: #d9eeff !important;
  border-radius: 0 !important;
  padding: 15px;
}
.spLabel2 {
  color: #585656 !important;
  font-size: 18px;
  font-weight: bolder !important;
}
textarea.form-control {
  height: auto;
  max-width: 100% !important;
  resize: none;
  font-size: 18px !important;
  /* text-align: center; */
  /* margin-left: 2em !important; */
}
.txtNoOfDays {
  margin-top: 18px !important;
  margin-left: 11px;
  max-width: 213px;
  float: left !important;
}
.dialogPrescription {
  max-width: 380px !important;
  overflow: hidden !important;
  height: 300px !important;
}

#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 25%;
  margin-top: -20px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border: solid 4px transparent;
  border-top-color: navy !important;
  border-left-color: navy !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.divFloatingActivieButtonMobile {
  margin-top: 125px;
  position: absolute;
  background: #224373b3;
  left: 0%;
  color: white;
  font-family: Oxygen !important;
  right: 0;
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  padding: 9px;
}

.divFloatingActivieButton {
  margin-top: 140px;
  position: absolute;
  background: #224373b3;
  left: 0;
  color: #f9fafd;
  font-family: Oxygen !important;
  width: 100vw !important;
  right: 0;
  cursor: pointer;
  text-align: right;
  padding: 9px;
}

.ag-item-label-audioOnly {
  z-index: 222;
  margin: 2px;
  color: white;
  font-size: calc(12px + 1vw);
  /* position: absolute;
  left: 5%;lapt
  60px !important60px !important
  top: 4%; */
  background-image: url('assets/images/phone_calling.gif') !important;
  width: 8vw !important;
  height: 8vw !important;
  border-radius: 50% !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  margin: 0 auto;
  margin-top: 19% !important;
  /* background: gray; */
  text-align: center;
  /* line-height: 2.5em;
  text-align: center; */
  border: 4px solid grey !important;
  border-radius: 50% !important;
}

.ag-item-audioOnly {
  background: transparent !important;
}
.password-icons {
  position: absolute;
  margin-left: 85%;
  top: 16px;
  cursor: pointer;
}
.delMedicine {
  color: red !important;
  height: 14px;
  cursor: pointer !important;
  padding: -3px;
  float: right !important;
  margin-top: -2em !important;
}

.ag-item-label2-audioOnly {
  color: #ffffff;
  text-align: center !important;
  text-transform: capitalize !important;
}
.btnSendChat_CHAT {
  /* margin-left: 91%; */

  right: 25px;
}
.divContainer_chat_main {
  min-width: 100% !important;
}
.username-lft {
  position: absolute;
  left: 38%;
}
@media (max-width: 600px) {
  .username-lft {
    left: 75%;
  }
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

#userDP {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 2px solid #dcd8d8;
}
@media (max-width: 760px) {
  #userDP {
    width: 100px;
    height: 100px;
    left: -25px;
    position: relative;
  }
  .patient-details-mobile-view img {
    left: 1px !important;
  }
}

.fll {
  float: left;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .safari_only_header {
      margin-top: 56px !important;
    }
    .navbar-light .navbar-nav .nav-link {
      font-size: 15px !important;
    }
    .___optionsDiv___ {
      position: absolute !important;
      background-color: #ffffff !important;
      padding: 0 !important;
      font-size: 14px !important;
      /* min-width: 160px !important; */
      color: black !important;
      /* box-shadow: 0px 8px 16px 0px rgb(234 155 155 / 20%) !important; */
      z-index: 9999 !important;
    }
  }
}
.read-more-less--more,
.read-more-less--less {
  color: #999;
}

#footer {
  bottom: 0;
  width: 100%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 960px) {
  #divConfirmed {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
  }
  .divMar {
    margin-bottom: 20px;
  }
  .consultation-nf {
    margin-left: -100% !important;
    position: relative;
  }
  .width-max {
    width: 100% !important;
    margin-left: -0.2% !important;
  }
  .padding-wd {
    /* margin:3px 10px 21px 6px !important; */
    padding: 15px 10px !important;
  }
  .body-bg {
    background-color: transparent !important;
  }
}

/* DivTable.com */
.spRibbon {
  float: left;
  width: fit-content;
}
.divTable {
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  display: table-cell;
  padding: 3px 10px;
  vertical-align: middle;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}
/* .MuiFab-sizeSmall {
  width: 30px !important;
  height: 25px !important;
} */
.MuiIconButton-root {
  padding: 0px !important;
}
.makeStyles-fab-4 {
  margin: 1px !important;
}

.___optionsDiv___ {
  position: absolute !important;
  background-color: #ffffff !important;

  padding: 3px !important;
  font-size: 14px !important;
  /* min-width: 160px !important; */
  color: black !important;
  /* box-shadow: 0px 8px 16px 0px rgb(234 155 155 / 20%) !important; */
  z-index: 9999 !important;
}
.autocomplete {
  background: #ffffff !important;
  /* z-index: 9999 !important; */
  position: relative !important;
  min-width: 160px !important;
}
.divAutoContatiner {
  margin-bottom: 7px !important;
  margin-left: -50px;
}
@media not screen and (-moz-windows-theme) {
  .divAutoContatiner {
    margin-bottom: 14px !important;
    margin-left: 8px !important;
  }
  _:-moz-ui-valid,
  .___optionsDiv___ {
    position: absolute !important;
    background-color: #ffffff !important;
    padding: 0 !important;
    font-size: 14px !important;
    /* min-width: 160px !important; */
    color: black !important;
    /* box-shadow: 0px 8px 16px 0px rgb(234 155 155 / 20%) !important; */
    z-index: 9999 !important;
  }
}

.markerLabel {
  white-space: pre;
  color: red;
  font-size: 16px;
  font-weight: bolder;
  margin-top: 5px;
  margin-right: 3px;
  float: right;
  /* height: max-content; */
  font-family: 'Oxygen';
}

.profile-picture-input {
  color: transparent;
  background: none !important;
  border: none !important;
  max-width: 7.5em;
  cursor: pointer;
  position: relative;
  z-index: 22;
  /* display: none; */
}
.profile-picture-input::-webkit-file-upload-button {
  visibility: hidden;
  user-select: none;
}
.profile-picture-input input[type='file']::-webkit-file-upload-button {
  visibility: hidden !important;
}
input::file-selector-button {
  visibility: hidden;
}

.profile-picture-input::before {
  content: 'Upload Picture';
  color: #224373;
  display: inline-block;
  background: #fff;
  border-radius: 0.5em;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  /* -webkit-user-select: none; */
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  max-width: 11em;
  z-index: 22;
  opacity: 0;
}

.profile-picture-input:hover::before {
  border-color: black;
}
.profile-picture-input:active {
  outline: 0;
}
.profile-picture-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.upload-imgs {
  /* position: absolute; */
  color: #224373;
  display: inline-block;
  background: #fff;
  border-radius: 0.5em;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  /* -webkit-user-select: none; */
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  max-width: 11em;
  top: -25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* left: 220px; */
}
.signature-picture-input {
  color: transparent;
  background: none !important;
  border: none !important;
  /* max-width: 7.5em; */
  max-width: 150px;
  cursor: pointer;
}
.signature-picture-input::-webkit-file-upload-button {
  visibility: hidden;
}
.signature-picture-input::before {
  content: 'Change Signature';
  color: #224373;
  display: inline-block;
  background: #fff;
  border-radius: 0.5em;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  max-width: 11em;
}
.signature-picture-input:hover::before {
  border-color: black;
}
.signature-picture-input:active {
  outline: 0;
}
.signature-picture-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #e90000;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  color: white !important;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #e90000;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  80% {
  }

  100% {
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .content {
    /* margin-top: 20.5%; */
    margin-top: 90px !important;
  }
  .disp-flex {
    display: block !important;
    margin-left: 14px;
  }
  .filter-close {
    margin-left: 75%;
  }
  .divFloatingActivieButtonMobile {
    margin-top: 160px;
  }
  .new-reset {
    left: 73.5%;
  }
}
.ht-50 {
  height: 50px !important;
  width: 100%;
}
.color-red {
  color: red;
}

.badge-confirmed {
  color: #ffffff;
  background-color: #40cdb4;
  padding: 10px !important;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  margin: auto;
  width: 100px;
  padding: 10px 10px 10px 10px;
}

.badge-completed {
  color: #00864e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-reAssigned {
  color: #ffffff;
  background-color: #a162c7;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  display: block;
  margin: auto;
  width: 100px;
  padding: 10px 10px 10px 10px;
}
.badge-reSubmit {
  color: #ffffff;
  background-color: #a162c7;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  display: block;
  margin: auto;
  width: 130px;
  padding: 10px 10px 10px 10px;
}
.second__status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -10px 0;
  position: relative;
}
.follow__second {
  margin: 5px 0 !important;
}
.safdisabled {
  background-color: #b0b0b0 !important;
  color: #ffffff;

  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  display: block;
  margin: auto;
  width: 130px;
  padding: 10px 10px 10px 10px;
  font-size: 95%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: not-allowed;
}
.badge-waiting {
  /* font-size: 18px; */
  line-height: 20px;
  color: #ef9b55 !important;
  font-weight: bold !important;
  display: block;
  margin: auto;
}
.badge-cancelled {
  color: #ec7474;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-insession {
  color: #224373;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-awaiting,
.badge-requested {
  color: #ffffff;
  background-color: #ef9b55;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  display: block;
  margin: auto;
  width: 100px;
  padding: 10px 10px 10px 10px;
}
.badge-requested {
  width: 130px;
}
.table-sm td,
.table-sm th {
  font-family: Oxygen;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 14px;
  color: #224373;
  text-align: center;
}
.table-sm th {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff !important;
  text-align: center;
}
video {
  object-fit: contain !important;
}
.hrForCasenotes {
  height: 0.5px !important;
  color: #333 !important;
  background-color: #333 !important;
}
.wd-100 {
  width: 100% !important;
}
.reset-btn {
  margin-top: -3px !important;
}
.new-reset {
  margin-top: -50px !important;
  position: relative;
  left: 83.5%;
}
#userSig {
  width: 155px;
  height: 105px;
  border: 2px solid #ffffff;
}
button.btn {
  margin-top: 2px;
  margin-top: 3px;
}
/* .btnConsultation {
  padding: 10px 25px;
} */
.ag-item-label2 {
  color: #fff;
  text-align: center;
}

.feedback-list {
  display: inline;
}
.feedback-list-item {
  float: left;
  padding: 10px;
}
.starRatingImg {
  height: 34px;
}
.check__new {
  position: relative;
  display: flex;
  flex-direction: row;
  left: 150px;
  top: 15px;
}
.i_waive {
  left: 60%;
}
.safari__left {
  left: 110%;
}
.feewaived__btns {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
}
.align-left {
  text-align: left !important;
}
.margin-top-22 {
  margin: 10px 0;
}
.feedbackHeading {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #224373;
  margin-top: 30px;
}
.btn-blue {
  background: #66c0ff !important;
  border-radius: 5px !important;
  margin: 1em;
}
.btn-grey {
  background: #c4c4c4 !important;
  margin: 1em;
  border-radius: 5px !important;
}
.feedBackFooter {
  border-top: 0px;
  text-align: center !important;
  display: inline-block;
}
.modal-body {
  /* max-height: calc(100vh - 143px);
  min-height: calc(100vh - 143px); */
  overflow-y: auto;
}
.pan-container {
  height: auto !important;
}
.consultation-avatar-feedback {
  width: 100px;
  height: auto;
  border-radius: 50%;
  margin-top: 10px;
  display: inline-block;
}
.feedBackTextArea {
  background: #f7f7f7;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 5px;
}
/* .MuiInputBase-input, ::placeholder {
  color: rgb(138, 138, 138) !important;
} */
.fee__waived__new {
  display: flex;
}
.fee__waived__new input[type='checkbox'],
input[type='radio'] {
  width: 20px !important;
  margin-top: 60px;
  left: 5px;
}

.pat__no__consult {
  display: flex;
}
.pat__no__consult input[type='checkbox'],
input[type='radio'] {
  width: 20px !important;
  margin-top: 0px;
  left: 5px;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .password-icons {
    margin-left: 75% !important;
  }
  .upload-imgs {
    /* left: 130px; */
  }
  .new-reset {
    position: absolute;
    margin-left: 0%;
  }
}
.manage__clinic {
  border: none;
  padding: 4px 15px;
  border-radius: 20px;
  font-size: 16px !important;
  color: #224373;
  font-weight: 500;
  position: relative;
  top: 3px;
}
.cod-heading {
  font-size: 30px !important;
  font-weight: bold;
  color: #224373;
}

#feedBackModal > div.modal-content {
  /* height: 100vh !important; */
  border: 4px solid #66c0ff !important;
}

#otpModal > div.modal-content {
  /* height: 100vh !important; */
  height: 420px !important;
  border: 4px solid #66c0ff !important;
}

#rescheduleModal > div.modal-content {
  height: 750px;
}
.languagesKnown > div {
  min-height: 57px !important;
  height: auto !important;
}
/* .infiniteScrollTbl tbody {
  display: block;
  max-height: 500px;
  overflow-y: scroll;
}

.infiniteScrollTbl thead,
.infiniteScrollTbl tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
} */

.infiniteScrollTbl {
  overflow: auto;
  height: 100px;
}
.infiniteScrollTbl thead th {
  position: sticky;
  top: 0;
  z-index: 9999999 !important;
  background-color: #66c0ff;
}
/* .infiniteScrollTbl tbody td{
 
} */

.infiniteScrollTbl > tbody > tr > td {
  z-index: -999 !important;
}
.infiniteScrollTbl > tbody > tr > td > button {
  z-index: -9999 !important;
}

.infiniteScrollTbl > tbody > tr {
  z-index: -999 !important;
}

/* .infinite-scroll-component__outerdiv {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #ddd;
} */

.infinite-scroll-component__outerdiv::after {
  height: calc(100% - 20px) !important;
}
.infinite-scroll-component {
  -webkit-transform: translate3d(0, 0, 0);
}
/* ::-webkit-scrollbar {
  width: 0px !important;
  background: transparent; 
} */

.dialogPrePending > div {
  width: 500px !important;
  height: 160px !important;
  border: 4px solid #66c0ff;
}

.btnSave {
  margin: 18px 0px 0px 0px !important;
  padding: 12px 25px 11px 25px !important;
}
.btnSaveVacation {
  margin: 1px 0px 0px 0px !important;
  padding: 12px 25px 11px 25px !important;
}
.divWait {
  margin: 14px 100px;
  color: #f19b29 !important;
}
.pull-right {
  float: right !important;
}
.invitedHeading {
  font-size: 18px;
  text-align: center;
  display: block;
}
.videotimer_div {
  position: absolute;
  z-index: 1;
  margin-left: 80%;
  color: white;
  font-weight: 700;
  font-size: 20px;
}
.billing__heading h3 {
  font-size: 20px !important;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .merago_SAF_img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
  }
  .i_waive {
    left: 0%;
  }
  .map {
    width: 100% !important;
    margin-top: 1em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1em;
  }
  #inputregistrationYear {
    top: 10px;
  }
  .saf_padding,
  .derm_padding {
    position: relative;
    padding: 15px 15px 0 20px;
    top: -35px;
  }
  .skin__heading {
    padding-left: 10px;
  }
}
.store__forward {
  top: -5px;
  position: relative;
  padding: 10px 5px;
}
#divNewMessage {
  top: 0;
  margin: auto;
  font-weight: 600;
  font-size: 16px;
  width: fit-content;
  text-align: center;
  z-index: 9999;
}
.save-btn {
  font-size: 16px !important;
}

.modalCenter {
  top: 42px !important;
  bottom: 40px !important;
}
