.labelText {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #7e899a;
}
.dashed-border {
  border-bottom: 1px dashed rgba(154, 170, 193, 0.4);
}
/* background fixed */
.container-fluid {
  background: #ffffff;
}

/* modal */
@media (max-width: 576px) {
  .modal-content {
    position: relative;
    width: 100%;
    height: 600px;
  }
  .switchClinic__modal {
    width: 350px;
    height: 400px !important;
    overflow-y: auto;
  }
}

.displayText {
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #224373;
  margin-bottom: 5rem;
}
.medicine-name {
  font-family: Oxygen;
  font-weight: Bold;
  font-size: 14px;
  line-height: 20px;
  color: #224373;
}
.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.time-frst-hdr img {
  padding-right: 10px;
  width: 28px;
  padding-bottom: 4px;
}

.health-profile-card-border {
  border: 1px solid #ced6e1;
  border-radius: 16px;
  padding: 1em;
}

.detail-header {
  margin-top: 1em;
  color: #7e899a;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 20px;
}

.detail-info {
  color: #224373;
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.profileHeading {
  border-bottom: 1px solid #d8e2ef;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #224373;
}

input {
  border-radius: 6px;
  border: 1px solid #e1e7ef;

  /* width: -webkit-fill-available; */
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #224373;
}
input[type='radio'] {
  width: auto;
  top: 0px;
}
textarea {
  border-radius: 6px !important;
  border: 1px solid #e1e7ef;
  width: -webkit-fill-available;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #224373;
}
input disabled {
  background: #ccc;
}
.chatNotes {
  position: relative;
  background: #fff;
  margin: 6px 0 0px 0;
  /* height: calc(100vh - 230px); */
  height: 330px !important;
  min-height: 0;
  font-size: 12px;
  line-height: 18px;
  overflow-y: auto;
  width: 100%;
  float: right;
  padding-bottom: 100px;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .container-fluid {
    padding-right: 0px !important;
    padding-left: -1px !important;
  }
}
.btn_btn {
  padding: 10px 60px;
}

.button_width {
  width: 150px !important;
}
