
@import url("https://fonts.googleapis.com/css?family=Oxygen:400,700");
* {
  box-sizing: border-box;
}

.align-self-flex-end {
  align-self: flex-end;
}
.auto-animated .container-center-vertical,
.auto-animated .container-center-horizontal {
  opacity: 1;
}
.auto-animated div {
  opacity: 0;
  position: absolute;
  --z-index: -1;
}
.container-center-horizontal {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

}
.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}
.hidden,
.hidden * {
  visibility: hidden;
  pointer-events: none;
}
.listeners-active,
.listeners-active * {
  pointer-events: auto;
}
.listeners-active-click,
.listeners-active-click * {
  cursor: pointer;
}
.screen a {
  text-decoration: none;
  display: contents;
}
.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
input:focus {
  outline: none;
}
