:root {
  --black-haze: #f5f7fa;
  --dune: #37333366;
  --froly: #eb7373;
  --froly-2: #eb73734c;
  --midnight-blue: #224373;
  --mint-tulip: #cceeea;
  --mint-tulip-2: #cceeea4c;
  --mischka: #cdd5e1;
  --mystic: #e1e6ef;
  --mystic-2: #e4eafb;
  --nepal: #99aac1;
  --puerto-rico: #40cdb3;
  --puerto-rico-2: #40cdb34c;
  --royal-blue: #3f6afb;
  --royal-blue-2: #3f6afb33;
  --sail: #bbd5ff;
  --solitude: #ebf2ff;
  --storm-gray: #64758d;
  --white: #ffffff;
  --white-2: #ffffff38;
  --white-22: #ffffff38;

  --font-size-l: 16px;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-size-xl: 18px;
  --font-size-xxl: 24px;
  --font-size-xxxl: 32px;
  --font-size-xxxxl: 40px;

  --font-family-oxygen: 'Oxygen', Helvetica;
  --font-family-undefined-regular: 'undefined-Regular', Helvetica;
}
.oxygen-bold-nepal-14px {
  color: var(--nepal);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.oxygen-normal-midnight-blue-14px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}
.oxygen-bold-nepal-12px {
  color: var(--nepal);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-nepal-16px {
  color: var(--nepal);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-midnight-blue-14px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-royal-blue-14px {
  color: var(--royal-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-midnight-blue-16px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
.oxygen-normal-midnight-blue-16px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}
.oxygen-bold-midnight-blue-32px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-midnight-blue-12px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}
.oxygen-normal-nepal-16px {
  color: var(--nepal);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}
.oxygen-bold-storm-gray-12px {
  color: var(--storm-gray);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-midnight-blue-24px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-midnight-blue-40px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}
.oxygen-normal-nepal-14px {
  color: var(--nepal);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}
.oxygen-bold-midnight-blue-18px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-froly-14px {
  color: var(--froly);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-white-12px {
  color: var(--white);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-nepal-24px {
  color: var(--nepal);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-royal-blue-16px {
  color: var(--royal-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
.oxygen-normal-nepal-12px {
  color: var(--nepal);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
.oxygen-bold-royal-blue-12px {
  color: var(--royal-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.oxygen-bold-midnight-blue-12px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.oxygen-bold-white-12px-2 {
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-puerto-rico-18px {
  color: var(--puerto-rico);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-puerto-rico-12px {
  color: var(--puerto-rico);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-white-14px {
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-storm-gray-14px {
  color: var(--storm-gray);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.oxygen-normal-midnight-blue-32px {
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}
.oxygen-bold-froly-12px {
  color: var(--froly);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}
.oxygen-bold-froly-18px {
  color: var(--froly);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}
.border-1px-mischka {
  border: 1px solid var(--mischka);
}
.border-1px-mystic {
  border: 1px solid var(--mystic);
}
.border-1px-nepal {
  border: 1px solid var(--nepal);
}
.border-1px-midnight-blue {
  border: 1px solid var(--midnight-blue);
}
.border-1-6px-white {
  border: 1.6px solid var(--white);
}
.border-2-2px-white {
  border: 2.2px solid var(--white);
}
.border-2px-royal-blue {
  border: 2px solid var(--royal-blue);
}
