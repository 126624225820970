.content {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  margin-top: 5%;
  font-size: 16px !important;
  font-family: Oxygen;
}
.basic-layOut {
  background: #ffff !important;
}
img.photo {
  width: 100% !important;
  height: 100% !important;
}
.modal-open {
  overflow: inherit;
}
.modal-open .modal {
  margin-bottom: 0.75rem;
  padding-right: 0px;
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.heal-vault-img-bg {
  filter: saturate(3);
}
.heal-vault-img-round-active {
  width: 16px;
  padding: 3px;
  height: 16px;
  border-radius: 50%;

  color: #ffff;
}

.heal-vault-img-round {
  width: 16px;
  padding: 3px;
  height: 16px;
  border-radius: 50%;

  color: #ffff;
}
h1 {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #224373;
}
.p-help-consultation {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7e899a;
}
.p-help-consultation span {
  font-weight: bold;
}
.header-consultation-details {
  background: #ffff;
  border-radius: 10px;
  margin-top: 1rem;
}
.header-consultation-details .first-header {
  margin-top: 1rem;
  line-height: 40px;
  margin-bottom: 2rem;
  height: 60px;
  border-right: 1px solid #ced6e1;
}
.cosultid-frst-hrd {
  margin-top: 1rem;
  color: #7e899a;
  font-size: 14px;
  line-height: 18px;
}
h2 {
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  color: #224373;
}
.card {
  /* margin-top:1rem; */
  border-radius: 15px;
}

.dashboard-filter {
  float: left;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}
.dashboard-filters {
  background-color: #dbdbdb;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.dashboard-row {
  display: flex;
  margin-left: 40px;
  margin-top: -10px;
}
.select-placeholder-text {
  color: #224373 !important;
}
.message-container {
  width: 45px;
  height: 45px;
  border: 3px solid #66c0ff;
  border-radius: 50%;
  margin-left: 93%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
}
.message-containers {
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  border: 3px solid #66c0ff;
  border-radius: 50%;
  margin-left: 93%;
  margin-top: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: -12px;
  top: 5px;
  justify-content: center;
  z-index: 1;
}
.dir-col {
  position: relative;
  display: flex;
}
.filter-row {
  position: relative;
  margin-left: -7px;
}
.filter-sm-icon {
  position: absolute;
  margin-left: 10px;
}
.doc-rel {
  position: relative;
}
.doc-abs {
  position: absolute;
  color: #ec7474;
  font-weight: bold;
  top: 14px;
  left: 8px;
  width: 17px;
  height: 17px;
  border: 2px solid #9aa9c1;
  border-radius: 50%;
  background: white;
}
.disp-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-consultation-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -40px;
  margin-bottom: auto;
}
.consultation-a {
  font-size: 14px;
}
.divTexts {
  background: #e0e0e0;
  border-radius: 5px;
  padding: 1rem;
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  color: #224373 !important;
}
.divHeader_press {
  border: 1px solid #66c0ff;
  padding: 15px;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  background: #66c0ff;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}
.city-class {
  margin-left: 0px;
  font-weight: bold;
}
.btnHeaders {
  align-items: center !important;

  border-radius: 8px !important;
  cursor: pointer !important;

  height: 50px !important;
  float: right;

  max-width: 100% !important;
  color: white;
  text-align: center;
  min-width: 100%;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .pagination {
    margin-left: -10px !important;
    font-size: 15px;
  }
  .MuiFab-sizeSmall {
    width: 15px !important;
    height: 15px !important;
  }
  .makeStyles-fab-19 {
    margin: 4px !important;
  }
  .btnHeaders {
    margin-top: 24px !important;
    color: white;
    text-align: center;
    width: 130px !important;
    min-width: 130px !important;
  }

  .city-class {
    margin-left: -20px !important;
  }

  .table-sm td,
  .table-sm th {
    font-size: 10px !important;
    line-height: 20px;
  }
  .consultation-a {
    font-size: 12px;
  }
  .content {
    margin-top: 0% !important;
  }

  .my-consultation-top {
    margin-top: -30px !important;
    margin-bottom: 20px;
  }
  .filter-close {
    margin-left: 100% !important;
  }
  .filter-sm-icon {
    /* margin-left: -340px;
    margin-top: 12px;
    opacity: 0.4; */
    display: none;
  }
  .message-container {
    margin-left: 0%;
    top: -5px;
  }
  .history-message-container {
    margin-right: 10px;
  }
  .message-containers {
    margin-left: 84%;
    margin-top: -20%;
  }
  .mrt--10 {
    margin-top: -20px;
  }
  .care-on-demand {
    font-size: 20px !important;
    margin-top: -60px !important;
  }
  .history__left {
    margin-left: -10px;
  }
  .care-on-demands {
    font-size: 20px !important;
    /* margin-top: -50px !important; */
    /* margin-left: -15px !important; */
  }
  .flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dashboard-row {
    flex-direction: column;
    margin-left: 0px !important;
  }
  .dashboard-filters {
    height: auto;
  }
  .dashboard-filters:nth-child(1) {
    flex-direction: column;
  }
  .filter-row {
    margin-left: 0px !important;
  }
  .dir-col {
    display: flex;
    width: 95%;
    justify-content: space-between;
  }
  .filter-outline {
    margin-left: -35px !important;
  }
  filter-icon {
    cursor: pointer;
    margin-left: -35px !important;
  }
  .table-responsive {
    margin-left: -15px;
    margin-right: -15px;
    width: 108%;
  }
  .table-sm th {
    font-size: 14px;
  }
  .table-sm td {
    font-size: 10px;
  }
  .badge {
    padding: 10px 0 !important;
    font-size: 7px !important;
  }
  .badge-confirmed {
    width: 70px !important;
  }
  .badge-reAssigned {
    width: 55px !important;
  }
  .badge-reSubmit {
    width: 55px !important;
  }
  .badge-awaiting,
  .safdisabled {
    width: 45px !important;
  }
  .safdisabled {
    padding: 10px 0 !important;
    font-size: 7px !important;
  }
  .badge-soft-info {
    left: 0px !important;
    width: 40px;
  }
}

.message-notify {
  position: absolute;
  background-color: #ec7474;
  color: #ffffff;
  width: 25px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-left: 35px;
  font-size: 0.8em;
  top: 0px;
}
.newtooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.newtooltip .newtooltiptext {
  visibility: hidden;
  /* width: 120px; */
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* left: -80px; */
  left: -220px;
  position: absolute;
  top: 40px;
  z-index: 1;
}

.newtooltip:hover .newtooltiptext {
  visibility: visible;
}
filter-icon {
  position: relative;
  cursor: pointer;
  margin-bottom: 17px;
}
.last-header {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  margin-left: 3rem;
  line-height: 40px;
  margin-left: 2rem;
}
.time-frst-hdr {
  color: #3f6afb;
}

h3 {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  color: #224373;
}
.subjectRead {
  padding-left: 0.25rem;
  font-size: 12px;
}
.fromPatientUnReadMessage {
  padding-left: 0.25rem;
  padding-top: 0.25rem;
}
.fromPatientUnReadMessage {
  padding-left: 0.25rem;
  padding-top: 0.25rem;
}
h4 {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #224373;
}
h5 {
  font-size: 12px;
}
.margin-top-3 {
  margin-top: 3rem;
}
/* .margin-top-1 {
  margin-top: 1rem;
} */
.margin-top-2 {
  margin-top: 2rem;
}
h4 {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #224373;
}
.icon-item {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5e6e82;
  transition: all 0.2s ease-in-out;
  height: 2.5rem;
  width: 2.5rem;
  border: 0;
  font-size: 0.875rem;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}
.fc-scrollgrid-shrink-cushion {
  text-transform: capitalize !important;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  color: #7e899a;
}
.today-tmeGridWeek {
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #3f6afb;
}
.tmeGridWeek-header {
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 133% */

  color: #7e899a;
}
.day {
  font-weight: bold;
  font-size: 20px;
}
.timeGridWeek-slot {
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.fc .fc-timegrid-slot {
  height: 3rem !important;
}

.time-grid-week-title {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #224373;
}
.time-grid-week-textTime {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #7e899a;
}

.fc-day-tue .fc-timegrid-col-frame .fc-v-event,
.fc-day-tue .fc-daygrid-event {
  background-color: #cceeea;
  border: 1px solid #cceeea;
  padding: 5px;
}

.fc-day-thu .fc-timegrid-col-frame .fc-v-event,
.fc-day-thu .fc-daygrid-event {
  background-color: #cceeea;
  border: 1px solid #cceeea;
  padding: 5px;
}
.fc .fc-timegrid-col.fc-day-today,
.fc .fc-daygrid-day.fc-day-today {
  background-color: none !important;
}
.fc-timegrid-cols {
  overflow-y: scroll;
}

.fc-day-sat .fc-timegrid-col-frame .fc-v-event,
.fc-day-sat .fc-daygrid-event {
  background-color: #cceeea;
  border: 1px solid #cceeea;
  padding: 5px;
}
.fc-timegrid-event .fc-event-main {
  padding: 8px;
  border-radius: 1rem;
}
.fc-day-mon .fc-timegrid-col-frame .fc-v-event,
.fc-day-mon .fc-daygrid-event {
  background-color: #e4eafb;
  border: 1px solid #e4eafb;
  padding: 5px;
  cursor: pointer;
}

.fc-day-wed .fc-timegrid-col-frame .fc-v-event,
.fc-day-wed .fc-daygrid-event {
  background-color: #e4eafb;
  border: 1px solid #e4eafb;
  padding: 5px;
}
/* .fc-timegrid-event-harness > .fc-timegrid-event {
  position: relative !important;
} */
.fc-day-fri .fc-timegrid-col-frame .fc-v-event,
.fc-day-fri .fc-daygrid-event {
  background-color: #e4eafb;
  border: 1px solid #e4eafb;
  padding: 5px;
}
.fc-day-sun .fc-timegrid-col-frame .fc-v-event,
.fc-day-sun .fc-daygrid-event {
  background-color: #e4eafb;
  border: 1px solid #e4eafb;
  padding: 5px;
}
.calendar-popup-bg {
  /* background: #224373; */
  color: #224373 !important;
  box-shadow: 0px 16px 30px rgba(34, 67, 115, 0.2);
  border-radius: 16px;
  width: 270px;
  height: auto;
}

.calendar-popup-title {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #224373;
}
.calendar-popup-title .close {
  color: #ffffff;
}
.calendar-popup-reason {
  color: #7e899a;
}
.custom-file-upload-main {
  display: flex;
  border: 1px solid #97a0af;
  border-radius: 8px;
  overflow: hidden;
  padding: 11px 12px;
}
.custom-file-upload-inner {
  flex: 1 1 auto;
}
.MuiInputBase-input {
  box-sizing: border-box !important;
}
.doneIcon {
  padding-bottom: 0.8rem;
}
.WarningOutlinedIcon {
  padding-bottom: 0.5rem;
}
.btn-secondary:hover {
  background: #3f6afb;
}

.errorNoteInput {
  color: red !important;
}
.circle {
  background: #ffffff !important;
  border-radius: 50%;
  color: #fff;
  height: 3em;
  position: relative;
  width: 3em;

  border: 1px solid transparent;
}

.circleText {
  text-align: center;
  height: 76%;
  left: 40%;
  position: absolute;
  top: 40%;
  transform: translate(-35%, -32%);
  width: 71%;
}
.cl-mrl20 {
  left: -40px;
}
.close__end {
  left: 55px;
}
.map {
  height: 25vh !important;
  width: 85% !important;
  margin-top: 1.2em;
  margin-left: 5%;
  margin-right: 20%;
  margin-bottom: 1em;
}
.care-on-demand {
  font-weight: bold;
  font-size: 30px;
  color: #224373;
  margin-top: -25px;
}
.care-on-demands {
  font-weight: bold;
  font-size: 30px;
  color: #224373;
  /* margin-top: -70px; */
}
.multiselect-container > .active > a,
.multiselect-container > .active > a:hover,
.multiselect-container > .active > a:focus {
  background-color: #224373 !important;
}
.css-1r4vtzz,
.css-48ayfv {
  background-color: #dbdbdb !important;
  box-shadow: none !important;
}
.filter-close {
  margin-left: 75%;
  z-index: 100;
  cursor: pointer;
  margin-top: 12px;
  position: absolute;
}
.filter-outline {
  position: absolute;
  margin-left: -40px;
}
.MuiFab-root {
  width: 20px !important;
  height: 24px !important;
  min-height: 24px !important;
  min-width: 24px !important;
}
.clinic-icon {
  color: '#3f6afb !important';
  font-size: 18px !important;
}
.react-tel-input .form-control {
  padding: none !important;
}
.react-tel-input .phone__input {
  height: 50px !important;
  border-radius: 3px !important;
}
.virtual-format-text {
  margin-left: -20px;
}
::-webkit-input-placeholder {
  /* Edge */
  color: #9aaac1;
  font-size: 14px;
  font-weight: 400;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9aaac1;
  font-size: 14px;
  font-weight: 400;
}

::placeholder {
  color: #9aaac1;
  font-size: 14px;
  font-weight: 400;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
.image-pdf-canvas {
  max-height: 400px;
  overflow: scroll;
}
.serviceinfo__add-clinic-btns {
  float: right;
}
