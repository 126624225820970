.filterBy {
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: #224373;
}
.cardHeaderTitle {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #224373;
}
.container-fluid {
  background: #ffff;
}
.send__otp {
  background-color: #224373;
  color: #ffffff;
  border: none;
  padding: 12px 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 40px;
  margin-left: 40%;
}
.messageItem {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}
.readMessage {
  background: #f5f7fa;
  border-radius: 16px;
}
.fromPatientRedMessage {
  color: #7e899a;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.fromPatientUnReadMessage {
  color: #224373;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.subjectRead {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7e899a;
}
.messageTime {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #7e899a;
}
.card {
  box-shadow: none;
}
.default-bg {
  background: #e1e7ef !important;
}

.calenderBox {
  border: 1px solid #e1e7ef;
  width: 100%;
  border-radius: 16px;
  overflow-y: scroll;
}
.calendarBoxHeight {
  border: 1px solid #e1e7ef;
  width: 100%;
  border-radius: 16px;
  overflow-y: scroll;
  min-height: 398px;
  max-height: 398px;
}
.calenderHead-border {
  /* top: 52px; */
  border-bottom: 1px solid #e1e7ef;
}

.calend-date {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #224373;
  padding: 1em;
}

.timeSlot {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #7e899a;
  margin-top: 0.5em;
}

.consultCard {
  background: #e4eafb;
  border-radius: 8px;
  height: 64px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  align-items: center;
}

.flexProfile1 {
  flex: 50%;
  text-align: left;
}

.flexProfile2 {
  flex: 50%;
  text-align: right;
}

.consultProfile {
  margin-right: 1em;
}

.consultMenu {
  cursor: pointer;
  margin-left: 1em;
}

.consultName {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #224373;
}

.consultTime {
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #224373;
}

.dashBoardMessageBody {
  padding: 1em;
  border: 1px solid #e1e7ef;
  width: 100%;
  border-radius: 16px;
}
.flex-acc {
  display: block !important;
  width: 100%;
}
flex-see {
  display: flex !important;
  justify-content: space-between;
  flex-direction: row !important;
}
@media (max-width: 600px) {
  .flex-acc {
    display: block !important;
    width: 100%;
  }
}

.derma__care {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.store__forward {
  border: none;
  background-color: #224373;
  color: #ffffff;
  padding: 12px 15px;
  top: 15px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}
.switchClinic__modal {
  width: 350px !important;
  height: 400px !important;
  overflow-y: auto;
  display: flex;
}
.switchClinic__modal-btn-submit {
  border-radius: 5px !important;
  background: #224373;
}
.cod__inbox__container {
  text-align: center;
  font-family: Oxygen;
  padding: 20px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #e68a8a;
  margin-top: 20px;
}
