.singleloader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.singleloader:before,
.singleloader:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}
.singleloader:before {
  animation: ball1 1s infinite;
  background-color: #ff38d6;
  box-shadow: 30px 0 0 #fefd55;
  margin-bottom: 10px;
}
.singleloader:after {
  animation: ball2 1s infinite;
  background-color: #65c0fe;
  box-shadow: 30px 0 0 #03d141;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #fefd55;
  }
  50% {
    box-shadow: 0 0 0 #fefd55;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fefd55;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #03d141;
  }
  50% {
    box-shadow: 0 0 0 #03d141;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #03d141;
    margin-top: 0;
  }
}

.display__col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.singlecontainer {
  height: 15px;
  width: 5px;
  display: flex;
  position: relative;
  margin-left: 20px;
  .singlecircle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    animation: move 700ms linear 0ms infinite;
    margin-right: 30px;

    &:first-child {
      position: absolute;
      top: 0;
      left: 0;
      animation: grow 500ms linear 0ms infinite;
    }

    &:last-child {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 0;
      animation: grow 700ms linear 0s infinite reverse;
    }
  }
}

@keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes move {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(45px);
  }
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}
