.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #3f6afb;
  color: #3f6afb;
  cursor: pointer;
}

.nav-tabs .nav-link img {
  width: 16px;
  padding: 3px;
  height: 16px;
  border-radius: 50%;
  margin-right: 4px;
  margin-bottom: 1px;
}
.deny__learn {
  display: flex;
  gap: 10px;
}
.deny__button {
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}
.cookie__modals {
  width: 350px !important;
  height: 30px !important;
  overflow-y: auto;
}
.dialogPrePendings > div {
  width: 500px !important;
  height: 250px !important;
  border: 4px solid #66c0ff;
}
.dialogPrePendings p span {
  color: #66c0ff !important;
  cursor: pointer;
}
.nav-tabs .nav-link {
  border-bottom: 1px solid #d8e2ef;
  cursor: pointer;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #7e899a;
}
.nav-tabs {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}
.nav-tabs .nav-item {
  margin-right: 1rem;
}
.chat__time {
  font-size: 0.8em !important;
}
.header {
  color: #ff00cc;
}

.medicine-form .form-check-input {
  width: 20px !important;
  height: 20px !important;
}
.medicine-form .form-check-label {
  padding-top: 6px;
  padding-left: 6px;
}
.medicine-form {
  border: 1px solid #d8e2ef !important;
}
.medication-text {
  font-size: 14px;
  font-weight: normal;
}
.medication-text-instruct {
  font-size: 0.8rem !important;
}

.health-text {
  font-size: 0.8rem !important;
}

.medication-tab {
  border: 1px solid #d8e2ef !important;
  display: flex;
  /* width:86%; */
}

.datetime {
  popperplacement: top-start !important ;
}
.followup {
  color: green;
}
.font-medicine {
  font-size: 13px;
}
.font-doc {
  font-size: x-small;
}
.error {
  color: red;
}
.head-line-text {
  border: 1px solid royalblue !important;
  color: black;
}
.status-color {
  color: violet;
}

.status-color-awaits {
  color: blue;
}

.text-color-consult {
  color: black;
}
.patient-align {
  text-align: end;
  color: black;
}

.link-head-color {
  color: blue;
}
.bgcolor {
  background-color: #d2d9f1;
}
.bgcolornext {
  background-color: #eef2ff;
}
textarea {
  font-size: small !important;
}
.confirm-consult {
  font-weight: 600;
  padding: 0.2rem !important;
}
.status-color-post {
  color: #72796f;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
}
.stickDashboard {
  position: -webkit-sticky;
  position: sticky;

  z-index: 1;
}
.patient-text-weight {
  font-weight: bold;
  color: black;
}

.modal-fixed-right .modal-dialog {
  max-width: 540px !important;
}

.input-box {
  padding: 5px !important;
}

.placeholder-fontsize {
  font-size: small !important;
}

.consult-status {
  font-size: small !important;
}
.status-awaits {
  color: #0432ff;
  font-weight: 600;
}
.status-confirm {
  color: #7030a0;
  font-weight: 600;
}
.status-active {
  color: #385723;
  font-weight: 600;
}
.status-postsession {
  color: #595959;
  font-weight: 600;
}
.status-insession {
  color: #ff0000;
  font-weight: 600;
}
.consult-more {
  margin-top: 3rem !important;
}
.collapse-table {
  margin-bottom: 0px !important;
}
.stick-video {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1;
  padding-right: 5px;
}
.plus-icon {
  color: black;
  font-size: small;
}
.header-font {
  font-size: 17px;
  color: black;
  padding: 5px !important;
}
/* table {
  border-collapse: inherit !important;
} */
.calendar {
  width: 100px !important;
}
.calendar-daydate {
  font-size: 1.4rem !important;
  color: black !important;
}
.calendar-datename {
  font-size: 1rem !important;
  font-weight: bolder;
  color: black;
}
.calendar .calendar-month {
  background-color: #00b0f0 !important;
}
.calendar .calendar-day {
  border: 0.125rem solid #00b0f0 !important;
}
.time-box {
  border: 1px solid red;
}
.image-margin {
  margin-right: -53px !important;
}

.hw-button {
  height: 91px;
  width: 84px !important;
  background-color: #548235 !important;
  color: white !important;
  font-weight: 300 !important;
}
.calendar-month-name {
  font-size: 13px;
}
.calendar-time {
  font-size: 13px;
}
.patient-borderbox {
  border: 1px solid #00b0f0;
}
.link-head-color-id {
  color: #00b0f0;
}
.table-border {
  border-bottom: 1px solid #00b0f0 !important;
  border-top: 1px solid #00b0f0 !important;
}
.prescription-btncolor {
  background-color: #bf9000 !important;
  color: white !important;
  font-weight: 300 !important;
}
.followup-btncolor {
  background-color: #ffd966 !important;
  font-weight: 400 !important;
  color: black !important;
}
.denyconsultation-margin {
  margin-top: 16.2rem;
}
.send-btncolor {
  background-color: #00acff !important;
  color: black !important;
  font-weight: 400 !important;
}
.reschedule-btncolor {
  background-color: #3dacff !important;
  color: white !important;
}
.exit-btncolor {
  color: black !important;
}
.post-exit {
  margin-top: 5.6rem !important;
}
.endsession-btncolor {
  background-color: #c00000 !important;
  color: white !important;
  font-weight: 300 !important;
}
.cancel-btncolor {
  background-color: #7f7f7f !important;
  color: white !important;
  font-weight: 300 !important;
}

.reject-btncolor {
  background-color: #7f7f7f !important;
  color: white !important;
  font-weight: 300 !important;
}

.confirm-btncolor {
  background-color: #548235 !important;
  color: white !important;
  font-weight: 300 !important;
}
.id-fontbold {
  font-weight: bold !important;
}
.flex-grp {
  display: flex;
  align-items: center;
}
.flex-en {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex-st {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.password-icon {
  position: absolute;
  margin-left: 70%;
  cursor: pointer;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .largeLayout > div > video {
    min-height: 390px !important;
  }
  .largeLayout > div > div > video {
    min-height: 390px !important;
  }
  .btn {
    font-size: 10px !important;
  }
  .modal-fixed-right {
    max-width: 100% !important;
  }
  .remoteFirefox {
    z-index: 2909;
    display: inline-flex;
    position: absolute;
    max-height: 122px !important;
    max-width: 175px !important;
    float: unset;
    top: 10%;
    text-align: inherit;
    right: 3% !important;
  }
  .remoteOthers {
    z-index: 2909;
    display: inline-flex;
    position: absolute;
    max-width: 161px !important;
    max-height: 37% !important;
    float: unset;
    top: 10%;
    text-align: inherit;
    right: 9% !important;
  }
  /* Styles */
  .inboxSent {
    max-width: fit-content !important;
    padding: 2px !important;
    font-size: 10px;
  }
  .inboxSentContent {
    max-width: 87%;
    padding: 0 !important;
  }
  .password-icon {
    margin-left: 60%;
  }
  .btn_accept {
    top: 97px !important;
    left: 125px !important;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) and (max-width: 736px) {
  .modal-fixed-right {
    max-width: 100% !important;
  }
  .deny__button {
    left: 120px;
    position: relative;
  }
  .remoteFirefox {
    z-index: 2909;
    display: inline-flex;
    position: absolute;
    max-height: 122px !important;
    max-width: 175px !important;
    float: unset;
    top: 10%;
    text-align: inherit;
    right: 3% !important;
  }
  .remoteOthers {
    z-index: 2909;
    display: inline-flex;
    position: absolute;
    max-width: 161px !important;
    max-height: 37% !important;
    float: unset;
    top: 10%;
    text-align: inherit;
    right: 9% !important;
  }
  .inboxSent {
    max-width: fit-content !important;
    padding: 2px !important;
    font-size: 12px;
  }
  .inboxSentContent {
    max-width: 87%;
    padding: 0 !important;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  .modal-fixed-right {
    max-width: 100% !important;
  }
  .remoteFirefox {
    z-index: 2909;
    display: inline-flex;
    position: absolute;
    max-height: 122px !important;
    max-width: 175px !important;
    float: unset;
    top: 10%;
    text-align: inherit;
    right: 3% !important;
  }
  .remoteOthers {
    z-index: 2909;
    display: inline-flex;
    position: absolute;
    max-width: 161px !important;
    max-height: 37% !important;
    float: unset;
    top: 10%;
    text-align: inherit;
    right: 9% !important;
  }
  .inboxSent {
    max-width: fit-content !important;
    padding: 2px !important;
    font-size: 10px;
  }
  .inboxSentContent {
    max-width: 85%;
    padding: 0 !important;
  }
  .stick {
    position: sticky;
    z-index: 100;
  }
  .btnFloat {
    width: fit-content !important;
    padding-left: 5px !important;
    float: left;
  }
  .imgP {
    height: 80px;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: -19px !important;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto,
  .col-xxl,
  .col-xxl-1,
  .col-xxl-10,
  .col-xxl-11,
  .col-xxl-12,
  .col-xxl-2,
  .col-xxl-3,
  .col-xxl-4,
  .col-xxl-5,
  .col-xxl-6,
  .col-xxl-7,
  .col-xxl-8,
  .col-xxl-9,
  .col-xxl-auto {
    padding-right: 0px !important;
  }
  .calendar,
  .calendar-daydate,
  .calendar-time,
  .calendar-month-name {
    font-size: 10px !important;
  }
  .calendar {
    width: 80px !important;
    float: left !important;
    position: relative;

    line-height: unset !important;
  }
  .profile-card-3 h2 small {
    display: block;
    font-size: 9px;
    margin-top: 10px;
  }

  .h4,
  h4,
  center,
  p,
  .consult-status {
    font-size: 11px !important;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */

  .btn {
    font-size: 12px !important;
  }
  .remoteFirefox {
    z-index: 2909;
    display: inline-flex;
    position: absolute;
    max-height: 122px !important;
    max-width: 175px !important;
    float: unset;
    top: 10%;
    text-align: inherit;
    right: 3% !important;
  }
  .remoteOthers {
    z-index: 2909;
    display: inline-flex;
    position: absolute;
    max-width: 161px !important;
    max-height: 37% !important;
    float: unset;
    top: 10%;
    text-align: inherit;
    right: 9% !important;
  }
  .inboxSent {
    max-width: fit-content !important;
    padding: 2px !important;
    font-size: 12px;
  }
  .inboxSentContent {
    max-width: 87%;
    padding: 0 !important;
  }
  .table td,
  .table th {
    white-space: nowrap;
  }
  .dashboardSticky {
    position: sticky !important;
    top: calc(23.5% - 167px) !important;
    background: white;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .table td,
  .table th {
    white-space: nowrap;
  }
  .divSub1 {
    width: 100px;
  }
  .multipleSticky {
    font-weight: 600;
    position: relative !important;
    z-index: 0;
    background: #fff;
  }
  .stick {
    position: sticky;
    z-index: 100;
  }
  .divSub1 {
    height: 100px;
    border: 1px solid royalblue;
    width: 100px;
  }
  .divSub,
  .imgP {
    height: 100px !important;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -6px !important;
    margin-left: -15px;
  }
}
/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .stickDashboard {
    position: sticky;
    top: calc(22.3% - 159px);
    z-index: 1;
  }
  .dashboardHeaderH5 {
    position: sticky;
    z-index: 1000;
    margin-bottom: 0.25rem;
    background: #fff;
  }
  .table td,
  .table th {
    white-space: nowrap;
  }
  .divSub1 {
    width: 100px;
  }
  .multipleSticky {
    font-weight: 600;
    position: relative !important;
    z-index: 0;
    background: #fff;
  }
  .fs--1 {
    overflow: auto !important;
  }
  .stick {
    position: sticky;
    z-index: 100;
  }
  .divSub1 {
    height: 100px;
    border: 1px solid royalblue;
    width: 100px;
  }
  .divSub,
  .imgP {
    height: 100px !important;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -6px !important;
    margin-left: -15px;
  }
  .btn_accept {
    top: 55px !important;
    left: 125px !important;
  }
}
/**********
  iPad 3
  **********/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  .table td,
  .table th {
    white-space: nowrap;
  }
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .modal-fixed-right {
    max-width: 540px !important;
    left: calc(100vw - 540px) !important;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  }
  .table td,
  .table th {
    white-space: nowrap;
  }
  .multipleSticky {
    font-weight: 600;
    position: sticky;
    z-index: 0;
    background: #fff;
  }
  .divSub1 {
    width: 100px;
  }
  .stick {
    position: sticky;
    z-index: 100;
  }
  .divSub1 {
    height: 100px;
    border: 1px solid royalblue;
    width: 100px;
  }
  .divSub,
  .imgP {
    height: 100px !important;
  }
  .divSub,
  .imgP {
    height: 100px !important;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -6px !important;
    margin-left: -15px;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
  .table td,
  .table th {
    white-space: nowrap;
  }
  .fs--1 {
    overflow: auto !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) {
  .btn {
    font-size: 10px !important;
  }
  .dashboardHeaderH5 {
    position: sticky;
    z-index: 1000;
    margin-bottom: 0.25rem;
    background: #fff;
  }
  .multipleStickyTable td,
  .multipleStickyTable th {
    max-width: 126px !important;
    word-break: break-word;
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #edf2f9;
  }
  .multipleSticky {
    font-weight: 600;
    position: sticky !important;
    z-index: 0 !important;
    background: aliceblue;
  }
  .stick {
    position: sticky;
    z-index: 100;
  }
  .btnFloat {
    width: fit-content !important;
    padding-left: 5px !important;
    float: left;
  }
  .divSub1 {
    width: 65px;
  }
  .imgP {
    height: 65px;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: -11px !important;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto,
  .col-xxl,
  .col-xxl-1,
  .col-xxl-10,
  .col-xxl-11,
  .col-xxl-12,
  .col-xxl-2,
  .col-xxl-3,
  .col-xxl-4,
  .col-xxl-5,
  .col-xxl-6,
  .col-xxl-7,
  .col-xxl-8,
  .col-xxl-9,
  .col-xxl-auto {
    padding-right: 0px !important;
  }
  .calendar,
  .calendar-daydate,
  .calendar-time,
  .calendar-month-name {
    font-size: 8px !important;
  }
  .calendar {
    width: 65px !important;
    float: left !important;
    position: relative;

    line-height: unset !important;
  }

  .profile-card-3 h2 small {
    display: block;
    font-size: 9px;
    margin-top: 10px;
  }

  .h4,
  h4,
  center,
  p,
  .consult-status {
    font-size: 9px !important;
  }
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  .dashboardHeaderH5 {
    position: sticky;
    z-index: 1000;
    margin-bottom: 0.25rem;
    background: #fff;
  }

  .dashboardSticky {
    position: sticky !important;
    top: calc(35.5% - 167px) !important;
    background: white;
  }
  .multipleStickyTable td,
  .multipleStickyTable th {
    max-width: 194px !important;
    word-break: break-word;
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #edf2f9;
  }
  .stick {
    position: sticky;
    z-index: 100;
  }
  .btnFloat {
    width: fit-content !important;
    padding-left: 5px !important;
    float: left;
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto,
  .col-xxl,
  .col-xxl-1,
  .col-xxl-10,
  .col-xxl-11,
  .col-xxl-12,
  .col-xxl-2,
  .col-xxl-3,
  .col-xxl-4,
  .col-xxl-5,
  .col-xxl-6,
  .col-xxl-7,
  .col-xxl-8,
  .col-xxl-9,
  .col-xxl-auto {
    padding-right: 0px !important;
  }
  .divSub1 {
    width: 80px;
  }
  .imgP {
    height: 80px;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: -19px !important;
  }

  .calendar,
  .calendar-daydate,
  .calendar-time,
  .calendar-month-name {
    font-size: 10px !important;
  }
  .calendar {
    width: 80px !important;
    float: left !important;
    position: relative;

    line-height: unset !important;
  }

  .h4,
  h4,
  center,
  p,
  .consult-status {
    font-size: 11px !important;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) {
  .dashboardSticky {
    position: sticky !important;
    top: calc(29.5% - 167px) !important;
    background: white;
  }
  .multipleSticky {
    font-weight: 600;
    position: sticky !important;
    z-index: 0 !important;
    background: #fff;
  }
  .stick {
    position: sticky;
    z-index: 100;
  }
  .btnFloat {
    width: fit-content !important;
    padding-left: 5px !important;
    float: left;
  }
  .divSub,
  .imgP {
    height: 80px !important;
    width: 80px !important;
    padding-left: 2px;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -6px !important;
    margin-left: -15px !important;
  }
  .calendar,
  .calendar-daydate,
  .calendar-time,
  .calendar-month-name {
    font-size: 10px !important;
  }
  .calendar {
    width: 80px !important;
    float: left !important;
    position: relative;

    line-height: unset !important;
  }

  .h4,
  h4,
  center,
  p,
  .consult-status {
    font-size: 11px !important;
  }
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  .stick {
    position: sticky;
    z-index: 100;
  }
  .btnFloat {
    width: fit-content !important;
    padding-left: 5px !important;
    float: left;
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto,
  .col-xxl,
  .col-xxl-1,
  .col-xxl-10,
  .col-xxl-11,
  .col-xxl-12,
  .col-xxl-2,
  .col-xxl-3,
  .col-xxl-4,
  .col-xxl-5,
  .col-xxl-6,
  .col-xxl-7,
  .col-xxl-8,
  .col-xxl-9,
  .col-xxl-auto {
    padding-right: 0px !important;
  }
  .divSub1 {
    width: 80px;
  }
  .imgP {
    height: 80px;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: -19px !important;
  }

  .calendar,
  .calendar-daydate,
  .calendar-time,
  .calendar-month-name {
    font-size: 10px !important;
  }
  .calendar {
    width: 80px !important;
    float: left !important;
    position: relative;

    line-height: unset !important;
  }

  .h4,
  h4,
  center,
  p,
  .consult-status {
    font-size: 11px !important;
  }
}

@media only screen and (min-device-width: 411px) and (max-device-height: 823px) and (orientation: portrait) {
  .divSub1 {
    width: 80px;
  }
  .stick {
    position: sticky;
    z-index: 100;
  }
  .multipleSticky {
    font-weight: 600;
    position: sticky;
    z-index: 0;
    background: #fff;
  }
  .btnFloat {
    width: fit-content !important;
    padding-left: 5px !important;
    float: left;
  }
  .divSub,
  .imgP {
    height: 80px !important;
    width: 80px !important;
    padding-left: 6px;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -6px !important;
    margin-left: -15px !important;
  }
  .calendar,
  .calendar-daydate,
  .calendar-time,
  .calendar-month-name {
    font-size: 10px !important;
  }

  .calendar {
    width: 86px !important;
    float: left !important;
    position: relative;
    padding-left: 10px;
    line-height: unset !important;
  }

  .h4,
  h4,
  center,
  p,
  .consult-status {
    font-size: 11px !important;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) {
  .stickDashboard {
    position: sticky;
    top: calc(22.3% - 73px);
    z-index: 1;
  }
  .dashboardHeaderH5 {
    position: sticky;
    z-index: 1000;
    margin-bottom: 0.25rem;
    background: #fff;
  }
  .divSub1 {
    width: 80px;
  }
  .stick {
    position: sticky;
    z-index: 100;
  }
  .btnFloat {
    width: fit-content !important;
    padding-left: 5px !important;
    float: left;
  }
  .divSub,
  .imgP {
    height: 80px !important;
    width: 80px !important;
    padding-left: 6px;
  }
  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -6px !important;
    margin-left: -15px !important;
  }
  .calendar,
  .calendar-daydate,
  .calendar-time,
  .calendar-month-name {
    font-size: 10px !important;
  }

  .calendar {
    width: 86px !important;
    float: left !important;
    position: relative;
    padding-left: 10px;
    line-height: unset !important;
  }

  .h4,
  h4,
  center,
  p,
  .consult-status {
    font-size: 11px !important;
  }
}

.multipleSticky {
  font-weight: 600;
  position: sticky;
  z-index: 0;
  background: #fff;
}
.stuck {
  position: fixed;
  top: 0;
}

.active-consultation thead th,
.active-consultation td {
  font-size: 11px;
  padding: 0.5rem;
}
.dashboard-cardHeaderColor {
  background-color: #bce4c2 !important;
}
.fa-envelope {
  cursor: pointer;
}
.medications__icons {
  cursor: pointer;
}
a.active.nav-link {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0;
  color: #2c7be5;
}
.veritcal-nav {
  background-color: #edf2f9;
}
.nav-link {
  cursor: pointer;
}
.customFont {
  font-style: italic;
}
.message-row {
  cursor: pointer;
}

.dashboardSticky {
  position: sticky !important;
  top: calc(45% - 167px);
  background: white;
}
.dashboardHeader {
  top: calc(38.4% - 143px);
  position: sticky;
  background: white;
}
.dashboardHeaderH5 {
  position: sticky;
  z-index: 1000;
  margin-bottom: 0.25rem;
  background: #fff;
}
.imgP {
  max-width: 100px;
}

.text-nowrap {
  white-space: normal !important;
}

@media screen and (min-width: 320px) and (max-width: 500px) and (orientation: portrait) {
  .dashboardHeaderH5 {
    position: sticky;
    z-index: 1000;
    margin-bottom: 0.25rem;
    background: #fff;
  }
  .multipleStickyTable td,
  .multipleStickyTable th {
    max-width: 126px !important;
    word-break: break-word;
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #edf2f9;
  }
  .stickDashboard {
    position: -webkit-sticky;
    position: sticky;

    z-index: 1;
  }
}

.table td,
.table th {
  padding: 0.6rem !important;
}

.invitationHeader {
  position: sticky;
  background-color: rgb(255, 255, 255);
  padding: 1.25rem !important;
  padding-bottom: 0 !important;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.invitationHeaderContent {
  padding: 1.25rem !important;
  background-color: rgb(255, 255, 255) !important;
  /* top: 62.6406px; */
  padding-top: 0 !important;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding-top: 16px !important;
}

.page-link-disabled:hover {
  background-color: none !important;
}
.no-word-wrap {
  word-wrap: break-word !important;
  white-space: normal !important;
}
.phoneInputs {
  margin-top: 0px !important;
}
.PhoneInputInput,
.PhoneInputInput:hover,
.PhoneInputInput:focus {
  border: none !important;
  outline: none !important;
}
.PhoneInputInput--focus {
  border: none !important;
}
.PhoneInputCountryFlag-borderColor {
  border: none !important;
}

.phoneInputInvalid,
.phoneInputInvalid:hover,
.phoneInputInvalid:focus {
  border: red !important;
  /* outline: none !important; */
}
.phoneInputInvalidInput--focus {
  border: red !important;
}
.phoneInputInvalidCountryFlag-borderColor {
  border: red !important;
}

ul li {
  list-style: none;
}
.fabs {
  bottom: 0;
  position: fixed;
  margin: 1em;
  right: 0;
  z-index: 1000;
}

.fab {
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  text-align: center;
  color: #f0f0f0;
  margin: 25px auto 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  position: relative;
  z-index: 998;
  overflow: hidden;
  background: #42a5f5;
}

.fab > i {
  color: white;
  font-size: 2em;
  line-height: 55px;
  -webkit-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.fab:not(:last-child) {
  width: 0;
  height: 0;
  margin: 20px auto 0;
  opacity: 0;
  visibility: hidden;
  line-height: 40px;
}

.fab:not(:last-child) > i {
  font-size: 1.4em;
  line-height: 40px;
}

.fab:not(:last-child).is-visible {
  width: 40px;
  height: 40px;
  margin: 15px auto 10;
  opacity: 1;
  visibility: visible;
}

.fab:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
}

.fab:not(:last-child):nth-last-child(2) {
  -webkit-transition-delay: 20ms;
  transition-delay: 20ms;
}

.fab:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 40ms;
  transition-delay: 40ms;
}

.fab:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 60ms;
  transition-delay: 60ms;
}

.fab:not(:last-child):nth-last-child(5) {
  -webkit-transition-delay: 80ms;
  transition-delay: 80ms;
}

/*Chatbox*/

.chat {
  position: fixed;
  right: 85px;
  bottom: 20px;
  width: 400px;
  font-size: 12px;
  line-height: 22px;
  font-family: 'Roboto';
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  opacity: 0;
  box-shadow: 1px 1px 100px 2px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  -webkit-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.chat_fullscreen {
  position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
}
.chat_header {
  /* margin: 10px; */
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: 500;
  color: #f3f3f3;
  height: 55px;
  background: #42a5f5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 8px;
}
.chat_header2 {
  /* margin: 10px; */
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.chat_header .span {
  float: right;
}

.chat_fullscreen_loader {
  display: none;
  float: right;
  cursor: pointer;
  /* margin: 10px; */
  font-size: 20px;
  opacity: 0.5;
  /* padding: 20px; */
  margin: -10px 10px;
}

.chat.is-visible {
  opacity: 1;
  -webkit-animation: zoomIn 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  animation: zoomIn 0.2s cubic-bezier(0.42, 0, 0.58, 1);
}
.is-hide {
  opacity: 0;
}

.chat_option {
  float: left;
  font-size: 15px;
  list-style: none;
  position: relative;
  height: 100%;
  width: 100%;
  text-align: relative;
  margin-right: 10px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.chat_option img {
  border-radius: 50%;
  width: 55px;
  float: left;
  margin: -30px 20px 10px 20px;
  border: 4px solid rgba(0, 0, 0, 0.21);
}

.change_img img {
  width: 35px;
  margin: 0px 20px 0px 20px;
}
.chat_option .agent {
  font-size: 12px;
  font-weight: 300;
}
.chat_option .online {
  opacity: 0.4;
  font-size: 11px;
  font-weight: 300;
}
.chat_color {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 10px;
  float: left;
}

.chat_body {
  background: #fff;
  width: 100%;

  display: inline-block;
  text-align: center;
  overflow-y: auto;
}
#chat_body {
  height: 450px;
}
.chat_login p,
.chat_body li,
p,
a {
  -webkit-animation: zoomIn 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  animation: zoomIn 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}
.chat_body p {
  padding: 20px;
  color: #888;
}
.chat_body a {
  width: 10%;
  text-align: center;
  border: none;
  box-shadow: none;
  line-height: 40px;
  font-size: 15px;
}

.chat_field {
  position: relative;
  margin: 5px 0 5px 0;
  width: 50%;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
  color: #4b4b4b;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  border: none;
  outline: none;
  display: inline-block;
}

.chat_field.chat_message {
  height: 30px;
  resize: none;
  font-size: 13px;
  font-weight: 400;
}
.chat_category {
  text-align: left;
}

.chat_category {
  margin: 20px;
  background: rgba(0, 0, 0, 0.03);
  padding: 10px;
}

.chat_category ul li {
  width: 64%;
  height: 30px;
  background: #fff;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  font-size: 13px;
  cursor: pointer;
  line-height: 30px;
  color: #888;
  text-align: center;
}

.chat_category li:hover {
  background: #83c76d;
  color: #fff;
}
.chat_category li.active {
  background: #83c76d;
  color: #fff;
}

.tags {
  margin: 20px;
  bottom: 0px;
  display: block;
  width: 120%;
}
.tags li {
  padding: 5px 10px;
  border-radius: 40px;
  border: 1px solid rgb(3, 117, 208);
  margin: 5px;
  display: inline-block;
  color: rgb(3, 117, 208);
  cursor: pointer;
}
.fab_field {
  width: 100%;
  display: inline-block;
  text-align: center;
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.fab_field2 {
  bottom: 0px;
  position: absolute;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  z-index: 999;
}

.fab_field a {
  display: inline-block;
  text-align: center;
}

#fab_camera {
  float: left;
  background: rgba(0, 0, 0, 0);
}

#fab_send_channelMessage {
  /* float: right;
  padding: 0;
  position: absolute;
  top: 88%; */
  margin-left: 94%;
  top: 55px;
  position: relative;
  background: rgba(0, 0, 0, 0);
}

@page {
  size: A4;
}
.fab_field .fab {
  width: 35px;
  height: 35px;
  box-shadow: none;
  margin: 5px;
}

.fab_field .fab > i {
  font-size: 1.6em;
  line-height: 35px;
  color: #bbb;
}
.fab_field .fab > i:hover {
  color: #42a5f5;
}

.chat_converse {
  position: relative;
  background: #fff;
  margin: 6px 0 0px 0;
  height: 295px !important;
  min-height: 0;
  font-size: 12px;
  line-height: 18px;
  overflow-y: auto;
  width: 100%;
  float: right;
  padding-bottom: 100px;
}
.chat_converse2 {
  height: 100%;
}
.chat_list {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.chat_list .chat_list_item {
  opacity: 0;
  visibility: hidden;
}

.chat .chat_converse .chat_msg_item {
  position: relative;
  margin: 8px 0 15px 0;
  padding: 8px 10px;
  max-width: 60%;
  display: block;
  word-wrap: break-word;
  border-radius: 3px;
  -webkit-animation: zoomIn 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  animation: zoomIn 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  clear: both;
  z-index: 999;
}
.status {
  margin: 45px -50px 0 0;
  float: right;
  font-size: 11px;
  opacity: 0.3;
}
.status2 {
  margin: -10px 20px 0 0;
  float: right;
  display: block;
  font-size: 11px;
  opacity: 0.3;
}
.chat .chat_converse .chat_msg_item .chat_avatar {
  position: absolute;
  top: 0;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin .chat_avatar {
  left: -52px;
  background: rgba(0, 0, 0, 0.03);
}

.chat .chat_converse .chat_msg_item.chat_msg_item_user .chat_avatar {
  right: -52px;
  background: rgba(0, 0, 0, 0.6);
}

.chat .chat_converse .chat_msg_item .chat_avatar,
.chat_avatar img {
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin {
  margin-left: 60px;
  float: left;
  background: rgba(0, 0, 0, 0.03);
  color: #666;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_user {
  margin-right: 20px;
  float: right;
  background: #42a5f5;
  color: #eceff1;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin:before {
  content: '';
  position: absolute;
  top: 15px;
  left: -12px;
  z-index: 998;
  border: 6px solid transparent;
  border-right-color: rgba(255, 255, 255, 0.4);
}

.chat_form .get-notified label {
  color: #077ad6;
  font-weight: 600;
  font-size: 11px;
}

input {
  position: relative;

  /* font-family: 'Roboto';
  font-size: 12px; */
  line-height: 20px;
  /* font-weight: 500; */
  color: #4b4b4b;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  outline: none;
  background: #fff;
  display: inline-block;
  resize: none;
  padding: 5px;
  border-radius: 3px;
}
.chat_form .get-notified input {
  margin: 2px 0 0 0;
  border: 1px solid #83c76d;
}
.chat_form .get-notified i {
  background: #83c76d;
  width: 30px;
  height: 32px;
  font-size: 18px;
  color: #fff;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
  margin: 2px 0 0 -30px;
  position: absolute;
  border-radius: 3px;
}
.chat_form .message_form {
  margin: 10px 0 0 0;
}
.chat_form .message_form input {
  margin: 5px 0 5px 0;
  border: 1px solid #e0e0e0;
}
.chat_form .message_form textarea {
  margin: 5px 0 5px 0;
  border: 1px solid #e0e0e0;
  position: relative;
  width: 90%;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #4b4b4b;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  outline: none;
  background: #fff;
  display: inline-block;
  resize: none;
  padding: 5px;
  border-radius: 3px;
}
.chat_form .message_form button {
  margin: 5px 0 5px 0;
  border: 1px solid #e0e0e0;
  position: relative;
  width: 95%;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  outline: none;
  background: #fff;
  display: inline-block;
  resize: none;
  padding: 5px;
  border-radius: 3px;
  background: #83c76d;
  cursor: pointer;
}
strong.chat_time {
  padding: 0 1px 1px 0;
  font-weight: 500;
  font-size: 8px;
  display: block;
}

/*Chatbox scrollbar*/

/*Element state*/

.is-active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.is-float {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.32);
}

.is-loading {
  display: block;
  -webkit-animation: load 1s cubic-bezier(0, 0.99, 1, 0.6) infinite;
  animation: load 1s cubic-bezier(0, 0.99, 1, 0.6) infinite;
}
/*Animation*/

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes load {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
/* SMARTPHONES PORTRAIT */

@media only screen and (min-width: 300px) {
  .chat {
    width: 250px;
  }
}
/* SMARTPHONES LANDSCAPE */

@media only screen and (min-width: 480px) {
  .chat {
    width: 300px;
  }
  .chat_field {
    width: 65%;
  }
}
/* TABLETS PORTRAIT */

@media only screen and (min-width: 768px) {
  .chat {
    width: 300px;
  }
  .chat_field {
    width: 65%;
  }
}
/* TABLET LANDSCAPE / DESKTOP */

@media only screen and (min-width: 1024px) {
  .chat {
    width: 300px;
  }
  .chat_field {
    width: 65%;
  }
}
/*Color Options*/

.blue .fab {
  background: #42a5f5;
  color: #fff;
}

.blue .chat {
  background: #42a5f5;
  color: #999;
}

/* Ripple */

.ink {
  display: block;
  position: absolute;
  background: rgba(38, 50, 56, 0.4);
  border-radius: 100%;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  webkit-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
/*animation effect*/

.ink.animate {
  -webkit-animation: ripple 0.5s ease-in-out;
  animation: ripple 0.5s ease-in-out;
}

@-webkit-keyframes ripple {
  /*scale the element to 250% to safely cover the entire link and fade it out*/

  100% {
    opacity: 0;
    -moz-transform: scale(5);
    -ms-transform: scale(5);
    webkit-transform: scale(5);
    -webkit-transform: scale(5);
    transform: scale(5);
  }
}

@keyframes ripple {
  /*scale the element to 250% to safely cover the entire link and fade it out*/

  100% {
    opacity: 0;
    -moz-transform: scale(5);
    -ms-transform: scale(5);
    webkit-transform: scale(5);
    -webkit-transform: scale(5);
    transform: scale(5);
  }
}
::-webkit-input-placeholder {
  /* Chrome */
  color: #bbb;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #bbb;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #bbb;
}
:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #bbb;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
}
.loader {
  left: 50%;
  top: 40%;
  z-index: 1000;
  position: absolute;
}
.active {
  background-color: inherit !important;
}
.largeLayout {
  border-radius: 6px;
  height: 100%;
  width: 100%;
  max-height: fit-content !important  ;
  grid-area: span 12 / span 24/13/25;
  grid-row-start: span 12;
  grid-column-start: span 24;
  grid-row-end: 13;
  grid-column-end: 25;
}
.smallLayout:nth-child(even) {
  z-index: 3000;
  display: inline-flex !important;
  position: absolute;
  max-height: 120px;
  max-width: 120px;
  float: unset;
  top: 2%;
  text-align: inherit;
  margin: 6px !important;
  position: absolute;
  top: 0;
  border: 1px solid #7c848a;
  background: #000000;
}
.smallLayout:nth-child(odd) {
  z-index: 3000;
  display: inline-flex !important;
  position: absolute;
  max-height: 120px;
  max-width: 120px;
  float: unset;
  top: 2%;
  text-align: inherit;
  right: 35px;
  position: absolute;
  margin: 6px !important;
  border: 1px solid #7c848a;
  background: #000000;
  /* left: 100%; */
  top: 0;
}

.largeLayout > div > div > video {
  /* max-height: 390px !important; */
}

.ssLayout > div > div > video {
  max-height: fit-content !important;
}
video {
  /* transform: none !important; */
}
.btn-falcon-default {
  color: #224373 !important;
}

@supports (-moz-appearance: none) {
  .largeLayout > div > div > video {
    width: max-content !important;
  }
  .patientLayout > div > div > video {
    display: block;
  }
  .patientLayout > div:nth-child(2) > i {
    transform: rotate(90deg);
  }
  .patientLayout > h1 {
    transform: rotate(90deg);
  }
  .largeLayout > div:nth-child(2) > i,
  h1 {
    transform: rotate(0deg);
  }
  .patientLayout.largeLayout > div:nth-child(2) {
    position: absolute;
    z-index: 2000;
    right: 100%;
    top: -9%;
    left: 90%;
    transform: rotate(90deg);
  }
  .ag-container {
    width: 360px;
    height: 100%;
    margin: 0 auto;

    border-radius: 12px;
  }

  .ag-btn-group {
    height: 70px;
    width: calc(100% - 6px);
    margin: 0 auto !important;
    position: absolute;
    bottom: 0;
    z-index: 222;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .patientLayout {
    transform: rotate(270deg) !important;
    overflow-y: clip;
  }
}

.largeLayout > div > i {
  color: white !important;
}
.smallLayout > div > i {
  color: white !important;
}

.anchor-tag {
  cursor: pointer;
  color: #00b0f0;
  text-decoration: underline;
}
.ag-btn.audioControlBtn .ag-icon-mic-off,
.ag-icon-camera-off {
  font-size: 37px !important;
}
.ag-item-label {
  position: absolute;
  z-index: 1 !important;
  margin: 2px;
  color: white;
  /* position: absolute;
  left: 5%;
  top: 4%; */
  font-size: 1em;
  width: 2.5em;
  /* height: 2.5em; agora name*/
  text-align: center;

  border-radius: 50%;
  background: #ff5656;
}
.ag-item-label-img {
  position: absolute;
  font-size: 1em;
  left: 5%;
  top: 4%;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 1em;
  color: white;

  margin: 27%;
}

.spRemoteStatus {
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 1.5s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 1.5s infinite ease-in-out; /* IE */
  animation: blink normal 1.5s infinite ease-in-out; /* Opera */
  margin: 0 auto;
  width: 28px;
  height: 28px;
  background-color: #ff5656;
  box-shadow: rgba(177, 127, 127, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
    rgba(255, 0, 0, 0.5) 0 2px 12px;

  display: flex;
  color: white;
  text-align: center;
  text-shadow: 3px 3px #000;
  text-shadow: -2px -2px #000;
  text-align: start;
  vertical-align: middle;
  line-height: 175%;
  left: -12px;
  padding: 2px;
  font-weight: bold;
  margin-right: 5px;
}
.btnGetLink {
  background: rgb(0 128 215) !important;
  border: 1px solid rgb(0 128 215) !important;
  height: 29px !important;
  padding: 3px !important;
}
.search-form {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.search-form > .form-group {
  flex: 1 1 auto;
  margin: 5px;
  font-size: 10px;
}
@media (max-width: 800px) {
  .search-form {
    flex-direction: column;
  }
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  overflow: hidden;
  position: absolute;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  flex-direction: column;
  background: #647cce !important;
  color: #fff !important;
}
.MuiDataGrid-footer {
  background: #647cce !important;
  color: #fff !important;
}

.MuiChip-colorPrimary {
  font-size: 10px !important;
  color: #fff;
  background-color: #6d95e7;
}
.MuiChip-colorSecondary {
  font-size: 10px !important;
  color: #fff !important;
  background-color: #dd6d6d !important;
}
.MuiChip-root {
  height: 23px !important;
}

/* Style 9
   ----------------------------- */
.nine h1 {
  text-align: center;
  font-size: 25px;
  text-transform: uppercase;
  color: #222;
  letter-spacing: 1px;
  font-family: 'Playfair Display', serif;
  font-weight: 400;
}
.nine h1 span {
  margin-top: 5px;
  font-size: 15px;
  color: #444;
  word-spacing: 1px;
  font-weight: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;

  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;
}

.nine h1 span:after,
.nine h1 span:before {
  content: ' ';
  display: block;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  height: 5px;
  background-color: #f8f8f8;
}

.spDetails {
  text-align: right !important;
}
button[aria-selected='true'] {
  border-bottom: 4px solid #ff4646;
}

.clock {
  width: 360px;
}
.date {
  width: 100%;
  text-align: center;
  font-size: 1rem;
}
.date,
.hr,
.min,
.sec,
.colon {
  color: var(--text);
  text-shadow: 0 0 10px var(--shadow-1), 0 0 15px var(--shadow-2),
    0 0 20px var(--shadow-2);
}
.hr,
.min,
.sec,
.colon {
  width: 20%;
  float: left;
  text-align: center;
  font-size: 1rem;
}
.toggle-btn {
  width: 40px;
  height: 25px;
  border-radius: 25px;
  position: absolute;
  top: 50px;
  right: 50px;
  background: var(--toggle);
}
.toggle-btn .control {
  width: 19px;
  height: 19px;
  border-radius: 25px;
  position: relative;
  top: 3px;
  background: var(--toggle-control);
}
.dark .toggle-btn .control {
  float: right;
  right: 3px;
}
.light .toggle-btn .control {
  left: 3px;
}
.toggle-btn .light-txt,
.toggle-btn .dark-txt {
  position: absolute;
  top: -100%;
}
.toggle-btn .light-txt {
  left: -80%;
  color: var(--light-txt);
}
.toggle-btn .dark-txt {
  left: 80%;
  color: var(--dark-txt);
}

/* set colors for dark theme */
.dark {
  --background: #000;
  --text: #fff;
  --shadow-1: #aeb6bf;
  --shadow-2: #34495e;
  --toggle: #fff;
  --toggle-control: #000;
  --dark-txt: #fff;
  --light-txt: #333;
}
/* set colors for light theme */
.light {
  --background: #ddd;
  --text: #666;
  --shadow-1: #999;
  --shadow-2: #bbb;
  --toggle: #666;
  --toggle-control: #fff;
  --dark-txt: #bbb;
  --light-txt: #666;
}

.twelve h1 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 160px;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 13px;
}
.twelve h1:before {
  background-color: #c50000;
  content: '';
  display: block;
  height: 3px;
  width: 75px;
  margin-bottom: 5px;
}
.twelve h1:after {
  background-color: #c50000;
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 3px;
  width: 75px;
  margin-bottom: 0.25em;
}

/* Style 8
   ----------------------------- */
.eight h1 {
  text-align: center;

  text-transform: uppercase;
  font-size: 26px;
  letter-spacing: 1px;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 16px 0;
  grid-gap: 22px;
}

.eight h1:after,
.eight h1:before {
  content: ' ';
  display: block;
  border-bottom: 2px solid #ccc;
  background-color: #f8f8f8;
}

/* STYLE 6
   ----------------------------- */
.six h1 {
  text-align: center;
  color: #222;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  word-spacing: 1px;
  letter-spacing: 0px;
  color: #c50000;
  font-family: 'Playfair Display', serif;
}
.six h1 span {
  text-transform: none;
  font-size: 20px;
  font-weight: normal;
  font-style: italic;
  font-family: 'Playfair Display', 'Bookman', serif;
  color: #999;
  word-spacing: 1px;
  letter-spacing: none;
}
.six h1:after,
.six h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 45px;
  height: 4px;
  content: '';
  right: 45px;
  margin: auto;
  background-color: #ccc;
}
.six h1:before {
  background-color: #d78b8b;
  left: 45px;
  width: 90px;
}

.divDate h1 {
  text-align: right !important;
}

.light > div {
  text-align: -webkit-right !important;
}
.divWelcome h1 {
  text-align: left !important;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator {
  color: rgb(255 255 255) !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  display: block;
  overflow: overlay !important;
  white-space: nowrap !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  text-overflow: clip !important;
}

.MuiChip-root {
  border-radius: 3px !important;
}
.pMerago {
  float: right;
  margin-right: 1em;
  /* padding: 1em; */
}
.pTerms {
  float: revert;
  display: initial;
  text-align: center;
}
.pLink {
  padding-top: 5px;
}
.pHaveAnAcc {
  text-align: center;
  float: revert;
}

.btnAdd {
  color: #fff;
  background-color: #3f51b5;
  margin-top: 15px !important;
  margin-left: 4px !important;
}
.txtPhone {
  width: 85% !important;
}

.doneIcon {
  margin-top: 28px;
  color: green;
  font-size: 35px !important;
}
.pdFieldset {
  padding: 20px !important;
  border-width: 2px !important;
  border-style: groove !important;
  border-color: threedface !important;
  border-image: initial;
}
.pdFieldset3 {
  padding: 20px !important;
  border-width: 1px !important;
  border-style: groove !important;
  border-color: threedface !important;
  border-image: initial;
  margin-left: 0px;
}
.pdFieldset2 {
  padding: 20px !important;
  border-width: 2px !important;
  border-style: groove !important;
  border-color: threedface !important;
  border-image: initial;
  /* min-height: 800px; */
  margin-left: 10px;
}
.pdLegend {
  width: auto !important;
}
.pmH2:before,
.pmH2:after {
  content: '';
  height: 2px;
  width: 39%;
  background: #d4d4d4;
  position: absolute;
  top: 50%;
  z-index: 2;
}
.pmH2:before {
  left: 0;
}
.pmH2:after {
  right: 0;
}
.pmH2 {
  color: #636363;
  margin: 0 0 15px;
  position: relative;
  text-align: center;
}

.closeModalIcon > svg {
  cursor: pointer !important;
  font-size: 35px !important;
}
.crudBtns {
  color: #fff;
  min-width: fit-content !important;
  margin-left: 4px !important;
}

.pmH3:before,
.pmH3:after {
  content: '';
  height: 2px;
  width: 30%;
  background: #d4d4d4;
  position: absolute;
  top: 50%;
  z-index: 2;
}
.pmH3:before {
  left: 0;
}
.pmH3:after {
  right: 0;
}
.pmH3 {
  color: #636363;
  margin: 0 0 15px;
  position: relative;
  text-align: center;
}

.divRadio {
  margin-left: 40px;
}
.react-tel-input .form-control {
  font-size: 16px;
  width: 100% !important;

  /* margin-top: 14px !important; */
  margin-bottom: 6px !important;
  /* padding: 26.5px 27px 27.5px 52px !important; */
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
}

.phoneInput {
  margin-top: 14px !important;
  margin-bottom: 6px !important;
}

.phoneInputInvalid {
  margin-top: 14px !important;
  margin-bottom: 6px !important;
  border-color: red !important;
}
.WarningOutlinedIcon {
  color: orange;
  margin-top: 28px;
  margin-left: 5px;
}

.AddDoctorModal {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  max-width: 40% !important;
  margin-left: 35% !important;
}
.AddLicenseModal {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  max-width: 50% !important;
  margin-left: 25% !important;
  top: 3em !important;
}
.AddRosterModal {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  max-width: 80% !important;
  margin-left: 10% !important;
  max-height: 900px !important;
}

.modalLarge {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  max-width: 75% !important;
  margin-left: 15% !important;
  max-height: 600px !important;
}
.AddRosterModal2 {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  max-width: 75% !important;
  margin-left: 15% !important;
  min-height: 800px !important;
}
.spRole {
  font-size: 15px !important;
  background-color: rgb(61 255 13 / 29%);
  color: rgb(6 104 5);
  /* background-color: rgba(34, 51, 84, 0.02); */
  padding: 4px 8px;
  font-size: 0.866667rem;
  border-radius: 6px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  max-height: 24px;
}
.MuiDataGrid-window {
  background: #fff !important;
}
.aName {
  float: left !important;
  margin: 0px !important;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol' !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.334 !important;
  /* color: rgb(85, 105, 255) !important; */
  text-decoration: none !important;
}
.spSpeciality {
  margin: 0px;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 400;
  font-size: 15px;
  line-height: 1.57;
  color: rgba(34, 51, 84, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fnt10 {
  font-size: 20px !important;
}
.modal-header {
  background-color: #1ca8ff;
  position: sticky;
  top: 0;
  z-index: 9999;
}
.MuiStepper-horizontal {
  padding: 0 !important;
}
.MuiChip-root {
  border-radius: 3px !important;
}
.MuiTablePagination-root {
  color: rgb(239 0 0 / 87%) !important;
  overflow: auto !important;
  font-size: 0.875rem !important;
  float: right !important;
}
.preview {
  max-width: 80px !important;
}
.mg20 {
  padding-top: 10px;
}
.btnSubmitInfo {
  position: fixed !important;
  top: calc(100vh - 80px) !important;
  width: 267px !important;
  margin-left: 61% !important;
}
.btnSubmitInfo2 {
  position: fixed !important;
  top: calc(100vh - 70px) !important;
  width: 267px !important;
  margin-left: -19% !important;
}
.btngetGeoLocation {
  margin-top: 1em !important;
}
.pdLegend > span {
  color: rgb(248, 149, 0);
  font-size: 20px;
}
.MuiFab-secondary {
  padding: 1em !important;
  /* max-width: 18%; */
  /* float: left; */
  margin-top: 22px !important;
  margin-left: 6px !important;
  width: 16px !important;
  height: 15px !important;
  font-size: 12px !important;
}

.MuiSelect-selectMenu {
  height: auto !important;
  overflow: hidden !important;
  min-height: 1.1876em !important;
  white-space: break-spaces !important;
}
.MuiChip-root > span {
  color: rgb(255, 255, 255) !important;
  margin-top: 3px !important;
  margin-left: 6px !important;
  padding: 2px !important;
  border-radius: 4px !important;
}
.chipAddRoster {
  width: 100%;
  height: 63px !important;
  border: 1px solid #3f51b5 !important;
  background: aliceblue !important;
}

.modal-title {
  width: 100% !important;
}
.addMulti {
  max-width: 7%;
  /* float: left; */
  margin-top: 2em !important;
  height: 56px;
}
.curInp {
  background: #ffffff !important;
  border: 1px solid #e1e7ef !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 24px !important;
  /* identical to box height, or 200% */
  width: 95% !important;
  margin-top: 13px !important;
  margin-left: 7px;
  padding: 14px !important;
  border-radius: 7px;
  border: 1px solid #cac9c9;
}
.divDays {
  width: fit-content !important;
  float: left !important;
  margin-top: 1em !important;
}
.divDays:nth-of-type(1) {
  width: fit-content !important;
  float: left !important;
  margin-left: 1px !important;
}
.btnAddMore {
  margin-top: 1em !important;
  margin-left: 21em !important;
}
.btnRoster {
  margin-left: 67% !important;
  color: #fff;
  background-color: #d11457 !important;
}

.momentInp {
  margin-top: 2em;
}

.is-active {
  transform: rotate(0deg) !important;
}
.time_picker_container {
  margin-top: 5em !important;
}

.year-picker {
  position: relative !important;
  outline: none !important;
  width: 44% !important;
  margin-top: 1em !important;
  list-style: none !important;
  font-size: 16px !important;
  text-align: left !important;
  background-color: #fff !important;
  border-radius: 4px !important;
  background-clip: padding-box !important;
  line-height: 1.5 !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  border: 1px solid transparent !important;
  border-color: #d9d9d9 !important;
}
.year-input {
  border: 0 !important;
  width: 100% !important;
  cursor: auto !important;
  outline: 0 !important;
  height: 100% !important;
  color: rgba(0, 0, 0, 0.65) !important;
  background: #fff !important;
  padding: 13px 19px !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
}

.MuiDialogContent-dividers {
  padding: 16px 24px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiTypography-h6 {
  font-size: 17px !important;
}

.modal-header-settings .close {
  z-index: 5000 !important;
}

.wrapper {
  background-color: white;

  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #90d3ff78;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ddlLabel {
  float: left !important;
  min-width: 22% !important;
  margin-top: 20px !important;
  margin-bottom: 0;
}
.ddlSelect {
  float: left !important;
  min-width: 67% !important;
  margin-top: 2px !important;
}
.ddlCascade {
  margin-top: 1em !important;
}

.messageBox {
  line-height: 1.9em;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 1em;

  border: #eee solid 2px;
  position: absolute;
  top: 17%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: rgb(0, 0, 0);
}

.messageBox2 {
  line-height: 1.9em;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 1em;

  border: #eee solid 2px;
  top: 17%;
  left: 50%;
  margin-right: -50%;

  color: rgb(0, 0, 0);
}
.tdNotAvailable {
  color: red;
}

#btnProfileOpen {
  position: absolute;
  background: #edf2f9;
  border: 0;
  color: black;

  margin-left: calc(100% - 124px);
  margin-top: calc(1% - 69px);
  z-index: 1000;
}

.MuiLink-underlineHover {
  text-decoration: underline !important;
}

.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}
.MuiDataGrid-cell {
  max-height: fit-content !important;
  overflow: auto;
  max-height: inherit;
  white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  max-height: inherit;
  width: 100%;
  white-space: initial;
  line-height: 16px;
}

#btnAddMore {
  margin-top: 22px;
  color: #3fb574 !important;
  border: 3px solid rgb(103 181 63 / 50%) !important;
}

#ddlLanguages {
  margin-top: 2px !important;
  line-height: 30px !important;
}

.btnAddMoreNew {
  margin-top: 17px !important;
  padding: 14px !important;
  width: 160px !important;
  background: rgb(64, 109, 180) !important;
}

.ddlLanguages > div > span {
  background: #406db4 !important;
}

.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: #428bca;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: 0.5em;
}

.rwd-table td:last-child {
  margin-bottom: 0.5em;
}

.rwd-table td:before {
  content: attr(data-th) ': ';
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: 0.4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: 0.5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}

/* .ag-btn-group {
  margin-left: 80px;
} */

.react_tinylink_card_media {
  display: block;
  height: 47px !important;
  /* position: relative; */
  background: transparent !important;
  /* flex: 0 0 125px; */
  overflow: hidden;
  transition: flex-basis 0.25s ease-in-out 0s;
}
[id^='player_'] {
  background: transparent !important;
}
video {
  /* max-height: 445px !important; */
  border-radius: 10px !important;
  max-height: 445px !important;
}
.ptntRecord {
  margin: 0;
  padding: 7px;
  font-family: Oxygen;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #ffffff;
}
.ag-btn-group-2 > img {
  cursor: pointer !important;
}
.ag-btn-group > span,
.ag-btn-group > div,
.ag-btn-group > img {
  cursor: pointer !important;
}
.addUserIcon,
.primeChatFloat,
.radio-img,
.smallLayout {
  cursor: pointer;
}
.largeLayout > h1.ag-item-label {
  position: absolute;
  left: 22%;
  top: -29%;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: #ff5656;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
  /* padding: 47%; */
  margin: 27%;
}

.MuiOutlinedInput-input {
  padding: 14.5px 14px !important;
}
.MuiOutlinedInput-root {
  position: relative !important;
  border-radius: 8px !important;
}
.MuiFormControl-marginNormal {
  margin-top: 4px !important;
  margin-bottom: 8px !important;
}
.MuiFormHelperText-contained {
  /* margin-left: 70% !important; */
  margin-right: 1% !important;
  text-align: right !important;
  float: right !important;
}

.PhoneInputInput,
.PhoneInputInput:hover,
.PhoneInputInput:focus {
  border: none !important;
  outline: none !important;
}
.PhoneInputInput--focus {
  border: none !important;
}
.PhoneInputCountryFlag-borderColor {
  border: none !important;
}

.phoneInputInvalid,
.phoneInputInvalid:hover,
.phoneInputInvalid:focus {
  border: red !important;
  /* outline: none !important; */
}
.phoneInputInvalidInput--focus {
  border: red !important;
}
.phoneInputInvalidCountryFlag-borderColor {
  border: red !important;
}

.react-tel-input1 .form-control {
  border: 2px solid red !important;
  border-radius: 8px !important;
  min-width: 100% !important;
  outline: none !important;
  padding: 14.5px 14px 13.5px 58px !important;
  transition: box-shadow ease 0.25s, border-color ease 0.25s !important;
}
.react-tel-input .special-label {
  display: none !important;
}
.MuiInputBase-input {
  min-width: 100% !important;
}
.pLabelInput {
  /* margin-top:calc(1px + 1%); */
  margin-top: 12px;
  width: fit-content;
  margin-bottom: 4px;
}
.prfilePicture {
  background: #ffffff !important;
  border: 1px solid #e1e7ef !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 24px !important;
  /* identical to box height, or 200% */
  text-align: center !important;
  margin-left: 10%;
  margin-top: 4%;
  display: flex !important;
  align-items: center !important;

  color: #224373 !important;
}
.fl {
  float: left !important;

  width: 48% !important;
}
.pTerms {
  float: revert;
  display: initial;
  text-align: center;
}
.pLink {
  padding-top: 5px;
}
.WarningOutlinedIcon {
  color: orange;
  margin-top: 13px;
  margin-left: 5px;
}
.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.26) !important;
}
.buttons-mainnormal2 {
  align-items: center !important;
  background-color: var(--royal-blue) !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  display: flex !important;
  height: 40px !important;
  justify-content: flex-end !important;
  left: 407px !important;
  min-width: 135px !important;
  mix-blend-mode: normal !important;
  padding: 0 46px !important;
  position: absolute !important;
  top: 637px !important;
}

.doneIcon {
  margin-top: 11px;
  color: green;
  font-size: 35px !important;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
  z-index: 4000;
}
.loader {
  left: 50%;
  top: 40%;
  z-index: 1000;
  position: absolute;
}
.btnProviderRegistration,
.btnLogin {
  align-items: center !important;
  background-color: var(--royal-blue);
  border-radius: 8px !important;
  cursor: pointer !important;
  /* display: flex !important; */
  height: 40px !important;
  float: right;
  margin-top: 24px !important;
  max-width: 100% !important;
  color: white;
  text-align: center;
  width: 100%;
}

.btnProviderRegistrationDisabled {
  align-items: center !important;
  background-color: '#748194';
  border-radius: 8px !important;
  cursor: no-drop !important;
  /* display: flex !important; */
  height: 40px !important;
  float: right;
  margin-top: 24px !important;
  max-width: 100% !important;
  color: white;
  text-align: center;
  width: 100%;
}

.btnProviderLoginDisabled {
  align-items: center !important;
  background-color: #748194 !important ;
  border-radius: 8px !important;
  cursor: no-drop !important;
  /* display: flex !important; */
  height: 40px !important;
  float: right;
  margin-top: 24px !important;
  max-width: fit-content !important;
  color: white;
  text-align: center;
  width: 100%;
}

.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #224373 !important;
}
#simple-tabpanel-1 {
  display: none !important;
}
#simple-tabpanel-2 {
  display: none !important;
}
#simple-tabpanel-3 {
  display: none !important;
}
#simple-tabpanel-4 {
  display: none !important;
}
#simple-tabpanel-0 {
  display: none !important;
}
.MuiTypography-h6,
.MuiTab-root {
  letter-spacing: normal !important;
  text-transform: none !important;
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  /* or 150% */

  display: block !important;
  align-items: center !important;

  color: #ffffff /* !important */;
}

.divSubHeader {
  margin-bottom: 11px;
}
.divProfileContainer {
  /* margin-top: 8% !important; */
  margin-bottom: 2% !important;
}
.makeStyles-grow-1 > header {
  top: 0;
  left: auto;
  right: 0;
  position: fixed !important;
}

.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}
legend > span {
  color: var(--midnight-blue) !important;
  font-family: var(--font-family-oxygen) !important;
  font-size: var(--font-size-m) !important;
}
.tag-item {
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px !important;
  color: var(--midnight-blue) !important;
  font-family: var(--font-family-oxygen) !important;
  font-size: var(--font-size-m) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  background-color: #90d3ff78;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}
.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-items {
  box-sizing: border-box;
  border-radius: 5px;
  /* padding: 6px !important; */
  color: var(--midnight-blue) !important;
  font-family: var(--font-family-oxygen) !important;
  font-size: var(--font-size-m) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  background-color: #d8e2ef;
  display: inline-block;
  font-size: 14px;
  height: 58px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  width: 100%;
  justify-content: space-between;
}
.tag-items > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiSelect-nativeInput {
  left: 0;
  width: 100%;
  bottom: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
.MuiSelect-selectMenu {
  height: auto !important;
  overflow: hidden !important;
  min-height: 1.1876em !important;
  white-space: break-spaces !important;
}
.MuiInputBase-input {
  min-width: 100% !important;
}
.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 16px;
}
.search-wrapper {
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 5px;
  min-height: 51px;
  position: relative;
}
#ddlLanguages_input {
  border: none;
  margin-top: 12px;
  background: transparent;
}
.MuiSelect-select.MuiSelect-select {
  padding-right: 0px;
  color: var(--midnight-blue);
  font-family: var(--font-family-oxygen);
  font-size: var(--font-size-m);
  font-style: normal;
}
.divPic {
  text-align: center !important;
}
.ddlSelect {
  min-width: 100%;
  margin-top: 0px;
  border: 1px solid #ced6e1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px !important;
  color: var(--midnight-blue) !important;
  font-family: var(--font-family-oxygen) !important;
  font-size: var(--font-size-m) !important;
  font-style: normal !important;
  font-weight: 700 !important;
}
.MuiInput-underline:before,
.MuiSelect-select.MuiSelect-select {
  border-bottom: 0 !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0 !important;
}
.search-wrapper {
  border: 1px solid #cccccc !important;
  border-radius: 8px !important;
  padding: 5px !important;
  min-height: 51px !important;
  position: relative !important;
}
.MuiInputBase-input {
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: bold !important;
  /* font-size: 12px !important; */
  line-height: 24px !important;
  color: #224373 !important;
  height: 3.5rem !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  padding: 0 10px !important;
}

.fileInput {
  background: #ffffff !important;
  border: 1px solid #e1e7ef !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 24px !important;
  text-align: center !important;
  /* margin-left: 10%; */
  /* margin-top: 4%; */
  display: flex !important;
  align-items: center !important;
  color: #224373 !important;
}
.MuiGrid-grid-md-6 {
  max-width: 47% !important;
}
.container-center-horizontal {
  pointer-events: none;
  display: inline-block !important;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.MuiOutlinedInput-input {
  padding: 18.5px 14px !important;
}

body {
  /* background: #e5e5e5 !important; */
  padding: 0 !important;
  margin: 0 !important;
}

.btnNext {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  float: unset !important;
  background-color: transparent !important;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  /* color: blue !important !important; */
  text-decoration: underline !important;
  color: var(--midnight-blue) !important ;
  font-family: var(--font-family-oxygen) !important;
  font-size: var(--font-size-m) !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.btnNext2 {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  float: unset !important;
  background-color: transparent !important;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  /* color: blue !important !important; */
  text-decoration: underline !important;
  color: var(--midnight-blue) !important ;
  font-family: var(--font-family-oxygen) !important;
  font-size: var(--font-size-m) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  max-width: fit-content;
  float: right !important;
  padding: 0px !important;
}
.divContainer {
  width: 368px;
  background: #e1e7ef;
  box-shadow: 0px 4px 30px rgba(34, 67, 115, 0.05);
  border-radius: 16px;
}
.divContainer_chat {
  width: auto;
  background: #e1e7ef;
  box-shadow: 0px 4px 30px rgba(34, 67, 115, 0.05);
  border-radius: 16px;
  min-width: 100%;
}
.divFooter {
  padding: 15px 0;
}
.divMainHeader {
  border-bottom: 1px solid #ced6e1;
  padding: 0;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}
.divHeader {
  border-bottom: 1px solid #ced6e1;
  padding: 15px;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #224373;
}
#chatSend {
  width: 99%;
  padding: 15px;
  /* margin: 7px; */
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgb(34 67 115 / 5%);
  border-radius: 32px;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #224373;
  /* or 150% */
  z-index: 0 !important;
}

.divBody {
  background: #e1e7ef !important;
}
.divchatContainer {
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 10px !important;
  padding-bottom: 5px;
  padding-top: 5px;
}
.divLeftChat_text {
  font-family: Oxygen !important;
  padding: 13px !important;
  margin-left: 25px !important;
  max-width: fit-content !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 10px rgb(34 67 115 / 5%) !important;
  border-radius: 8px !important;
  color: #224373 !important;
}

.divBody_pres::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 1px;
}

.divBody_pres::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.divBody_pres::-webkit-scrollbar-thumb {
  border-radius: 1px;
  /*background-color: #3F6AFB;*/
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #3f6afb),
    color-stop(0.72, #3f6afb),
    color-stop(0.86, #3f6afb)
  );
}

.chat_converse::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 1px;
}

.chat_converse::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.chat_converse::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #3f6afb),
    color-stop(0.72, #3f6afb),
    color-stop(0.86, #3f6afb)
  );
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 1px;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #3f6afb),
    color-stop(0.72, #3f6afb),
    color-stop(0.86, #3f6afb)
  );
}

.btnSendChat {
  z-index: 9999;
  position: absolute;
  /* float: right; */
  padding: 12px;
  right: 50px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .btnSendChat {
    bottom: 12px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .btnSendChat {
    bottom: 14px;
  }
}
.divRightChat_Text {
  /* display: table-column-group; */
  float: right;
  text-align: end;
  font-family: Oxygen !important;
  padding: 10px !important;
  margin-right: 25px !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  box-shadow: 0px 4px 10px rgb(34 67 115 / 5%) !important;
  border-radius: 8px !important;
  background: #3f6afb !important;
  color: white !important;
}
.divchatContainer_Right {
  text-align: end;
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 10px !important;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btnPrescription {
  flex: none;

  order: 1;
  flex-grow: 0;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 9px 8px;
  /* width: 112px; */
  margin: 1em;
  /* padding: 1em; */
  margin-right: 6px !important;
  height: 31px !important;
  /* left: 32px; */
  top: 4px;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
}

.divHeader_pres {
  border: 1px solid #1ca8ff;
  padding: 15px;
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  background: #1ca8ff;

  font-size: 18px;
  line-height: 24px;
  color: #ffffff !important;
  position: fixed !important;

  z-index: 999999;
  width: 846px;
  overflow: hidden;
}
.divBody_pres {
  padding: 0;
  margin: 0;
  position: relative;
  background: #fff;
  /* height: calc(100vh - 130px); */
  height: auto !important;
  min-height: 0;
  font-size: 12px;
  line-height: 18px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  float: right;
  border-radius: 10px 10px 0 0;
}
.btnAddNewPres {
  float: right !important;
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 18px !important;
  display: flex !important;
  align-items: center !important;
  text-align: right !important;
  color: #3f6afb !important;
  text-decoration: none !important;
}
.spLabel {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #7e899a !important;
}
.pd7 {
  padding: 7px;
}
.picker-panel {
  z-index: 6000 !important;
}

.remotee {
  max-width: 200px !important;
  max-height: 200px !important;
}
.btnConfirmDiv {
  max-width: 560px;
  margin-left: 51%;
}
.divAudioVideoIcons {
  margin: 50px 0px 0px 2px !important;
  position: absolute;
  z-index: 222;
}
.divAudioVideoIcons2 {
  margin: 96px 0px 0px 2px !important;
  position: absolute;
  z-index: 1 !important;
}
.sticky-header {
  top: 0;
  left: auto;
  right: 0;
  position: sticky !important;
  background-color: white;
  z-index: 99;
}
.inputTimePicker {
  max-width: 200px !important;
  float: left;
  margin-top: 15px !important;
}
#demo-simple-select-outlined {
  padding: 14px !important;
}
.quill {
  height: 300px;
  /* margin-bottom: 44px; */
  border: 2px solid #f0f0f0;
  border-radius: 10px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #416a9e !important;
  border-color: #416a9e !important;
}
.tblMessageGrid {
  line-height: 40px;
  color: #224373;
  font-family: Oxygen;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: 30px;
}
.divActualText {
  width: 300px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.gridCard {
  box-shadow: 0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%) !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-color: #fff !important;
  background-clip: border-box !important;
  border: 0 solid #edf2f9 !important;
  border-radius: 0.375rem !important;
}
.nav-tabs .nav-link.active {
  cursor: pointer !important;
}
.css-yk16xz-control {
  height: 55px !important;
}
.css-1pahdxg-control {
  height: 55px !important;
}
.divNoData {
  font-size: 22px;
  text-align: center;
  font-family: Oxygen;
  padding: 20px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #e68a8a;
  margin-top: 20px;
}
.message-tabs {
  /* background: #365687; */
  color: white;
  padding: 14px;
  margin: 0 !important;
}
.spOxygen,
.divActualText {
  font-family: Oxygen;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #224373;
  /* line-height: 20px !important; */
  color: #224373 !important;
  text-align: left;
}
.divText {
  background: #f0dbdb;
  border-radius: 5px;
  padding: 1rem;
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  /* line-height: 20px !important; */
  color: #224373 !important;
}
.page-link {
  border-radius: 36px !important;
  position: relative !important;
  display: block !important;
  margin-left: 5px !important;
  /* width: 29px !important; */
  text-align: center !important;
  border: 0 !important;
}
.page-link:hover {
  z-index: 2 !important;
  color: #fff !important;
  text-decoration: none !important;
  background-color: #aac5e6 !important;
  border-color: #fff !important;
}
.txtServiceInfo {
  max-width: 320px !important;
}
#fromDate_vacation,
#toDate_vacation {
  min-width: fit-content !important;
}
#demo-mutiple-checkbox {
  min-width: 133px !important;
}

#demo-mutiple-checkbox-label {
  padding: 8px !important;
}
.MuiInputBase-root,
.MuiFormControl-root {
  min-width: 100%;
}
a {
  font-family: Oxygen !important;
  font-style: normal !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px !important;
  color: #3f6afb;
}
.margin-top-n1 {
  margin-top: -15px;
}
.bgColorChange {
  color: #224373;
}
.bgColorSh {
  color: #9aaac1;
}
.padding-lf {
  padding-left: 0.5rem;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.PhoneInput--disabled input {
  background-color: #edf2f8 !important;
}
@media print {
  .pagebreak {
    break-after: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
  }
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.ScrollToTop {
  z-index: 999 !important;
}

.cookies_popup {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

/* .cookies{
color: #000 !important;
margin: 500px;  
margin-top: 200px;
padding: 50px;
border-radius: 4px;
width: 400px ! important;
position: absolute;
border-radius: 6px;
background-color: #ffffff !important;
} */

.cookies {
  z-index: 9999 !important;
  position: fixed;
  padding: 10px;
  border-radius: 4px;
}

.btn_decline {
  background-color: #ffffff !important;
  color: #000 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  padding-right: 20px !important;
  border-radius: 4px !important;
}

.btn_accept {
  /* background-color: #2B55C0 !important; */
  /* color: #ffffff !important; */
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 4px !important;
  margin-right: 90px !important;
  top: 33px;
  position: absolute;
  left: 67%;
}
.learnMore {
  border: 1px solid white;
  background-color: #353535 !important;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}
