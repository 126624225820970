.meeting.wrapper {
  height: auto;
  top: 256px;
  /* border: 1px solid #f3f3f3; */
  border-radius: 11px;
  background: transparent;
}

video {
  max-width: unset;
  position: unset !important;
}
.meeting .ag-header {
  z-index: 1000;
  padding-bottom: 20px;
  padding-left: 5px;
  /* padding: 20px 30px; */
  display: flex;
  position: absolute;
  top: calc(100vh - 358px) !important;
  /* justify-content: space-between; */
  align-items: center;
}

.ag-header-lead {
  font-size: 16px;
  line-height: 35px;
}

.meeting .ag-footer {
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.meeting .ag-main {
  position: relative;
}

.meeting .ag-footer :first-child {
  margin-right: 80px;
}
.header-logo {
  width: 60px;
  height: 35px;
  margin-right: 12px;
  filter: invert(69%) sepia(65%) saturate(3271%) hue-rotate(320deg)
    brightness(111%) contrast(139%);
}
.ag-container {
  width: calc(100% - 0px);
  height: 100%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.popup-box {
  background-color: #ffffff;
  border: 1px solid #b0b0b0;
  bottom: 0;
  display: none;
  height: 415px;
  position: fixed;
  right: 70px;
  width: 300px;
  font-family: 'Open Sans', sans-serif;
}
.round.hollow {
  margin: 40px 0 0;
}
.round.hollow a {
  border: 2px solid #ff6701;
  border-radius: 35px;
  color: red;
  color: #ff6701;
  font-size: 23px;
  padding: 10px 21px;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
}
.round.hollow a:hover {
  border: 2px solid #000;
  border-radius: 35px;
  color: red;
  color: #000;
  font-size: 23px;
  padding: 10px 21px;
  text-decoration: none;
}
.popup-box-on {
  display: block !important;
}
.popup-box .popup-head {
  background-color: #fff;
  clear: both;
  color: #7b7b7b;
  display: inline-table;
  font-size: 21px;
  padding: 7px 10px;
  width: 100%;
  font-family: Oswald;
}
.bg_none i {
  border: 1px solid #ff6701;
  border-radius: 25px;
  color: #ff6701;
  font-size: 17px;
  height: 33px;
  line-height: 30px;
  width: 33px;
}
.bg_none:hover i {
  border: 1px solid #000;
  border-radius: 25px;
  color: #000;
  font-size: 17px;
  height: 33px;
  line-height: 30px;
  width: 33px;
}
.bg_none {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
}
.popup-box .popup-head .popup-head-right {
  margin: 11px 7px 0;
}
.popup-box .popup-messages {
}
.popup-head-left img {
  border: 1px solid #7b7b7b;
  border-radius: 50%;
  width: 44px;
}
.popup-messages-footer > textarea {
  border-bottom: 1px solid #b2b2b2 !important;
  height: 34px !important;
  margin: 7px;
  padding: 5px !important;
  border: medium none;
  width: 95% !important;
}
.popup-messages-footer {
  background: #fff none repeat scroll 0 0;
  bottom: 0;
  position: absolute;
  width: 100%;
}
.popup-messages-footer .btn-footer {
  overflow: hidden;
  padding: 2px 5px 10px 6px;
  width: 100%;
}
.simple_round {
  background: #d1d1d1 none repeat scroll 0 0;
  border-radius: 50%;
  color: #4b4b4b !important;
  height: 21px;
  padding: 0 0 0 1px;
  width: 21px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 1px;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  height: 5px;
}

.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body {
  overflow-y: auto;
  min-height: 250px;
  max-height: 250px;
}
.card-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container {
  align-content: center;
}
.search {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
}
.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.type_msg {
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  height: 60px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts {
  list-style: none;
  padding: 0;
}
.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.active {
  /* background-color: rgba(0, 0, 0, 0.3);	 */
}
.user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.offline {
  background-color: #c23616 !important;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.user_info span {
  font-size: 20px;
  color: white;
}
.user_info p {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.6);
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  margin-right: 15px;
  font-size: 15px;
  background-color: #82ccdd;
  padding: 10px;
  font-size: 15px;
  position: relative;
}
.msg_cotainer_send {
  margin-top: auto;
  font-size: 15px;
  margin-bottom: auto;
  margin-right: 10px;
  color: white;
  border-radius: 25px;
  background-color: #f5cd4eb0;
  padding: 10px;
  position: relative;
}
.msg_time {
  position: absolute;
  left: 0;
  bottom: -24px;
  width: max-content;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
.msg_time_send {
  width: max-content;
  position: absolute;
  right: 0;
  bottom: -21px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
.msg_head {
  position: relative;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
@media (max-width: 576px) {
  .contacts_card {
    margin-bottom: 15px !important;
  }
}
.link {
  margin-left: 30px;
  margin-right: 30px;
}
.spInviteUser {
  color: rgb(0, 182, 237) !important;
  opacity: 1 !important;
  text-decoration: underline !important;
  cursor: pointer;
}
